@import "../base/var";

@media (min-width: 1024px){
    .location_finder {
        margin: 50px 0;

        .container {
            .row {
                .location_finder_img {
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                .location_finder_info {

                    .location_finder_info_inner {

                        .location_finder_info_inner_head {
                            background-color: $grey-background-color;
                            padding-top: 10px;

                            h3 {
                                display: inline;
                            }

                            a {
                                float: right;
                                margin: 3px 0 0 0;
                            }
                        }

                        .location_finder_detail {
                            padding: 0;

                            .row {
                                padding-top: 20px;
                                padding-left: 15px;
                                margin: 0;

                                .location_info {
                                    float: left;
                                    margin-right: 40px;

                                    .location_info_header {
                                        display: inline;
                                        padding-top: 10px;

                                        h3 {
                                            display: inline;
                                            font-size: 22px;
                                            color: $brand-blue-dark;

                                            i {
                                                color: $brand-color;
                                                font-size: 22px;
                                                padding-right: 5px;
                                            }
                                        }

                                        span {
                                            float: right;
                                            padding-top: 5px;
                                            color: $brand-color;
                                        }
                                    }

                                    .location_info_details {
                                        p {
                                            font-size: 16px;
                                            padding: 30px;
                                            color: $text-blue-color;
                                        }

                                        a {
                                            color: $brand-color;
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }

                            .location_banner_bottom {
                                background-color: $brand-blue-dark;
                                padding: 50px;

                                h3 {
                                    color: $white-color;
                                    font-size: 22px;
                                }

                                p {
                                    color: $white-color;
                                    font-size: 16px;
                                }

                                a {
                                    margin-right: 15px;
                                    margin-top: 15px;
                                }

                                span {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    padding: 10px;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}


@media (min-width: 0px) and (max-width: 1023px){
    .location_finder {
        .container {
            .row {
                .location_finder_info_inner_head {
                    background-color: $grey-background-color;
                    min-height: 60px;
                    padding-top: 10px;

                    h3 {
                        display: inline;
                    }

                    span {
                        float: right;
                        padding-top: 10px;
                        display: none;
                    }
                    a{
                        float: right;
                    }
                }

                .location_finder_img {
                    background-size: cover;
                    background-repeat: no-repeat;
                    min-height: 200px;
                }

                .location_finder_info {
                    min-height: 300px;
                    max-height: 500px;

                    .location_finder_info_inner {
                        .location_finder_info_inner_head {
                            background-color: $grey-background-color;
                            min-height: 60px;
                            padding-top: 10px;

                            h3 {
                                display: inline;
                            }

                            span {
                                float: right;
                                padding-top: 10px;
                                display: none;
                            }
                        }

                        .location_finder_detail {
                            padding: 0;

                            .row {
                                margin: 0;
                                padding-top: 20px;
                                min-height: 250px;
                                padding-left: 15px;

                                .location_info {
                                    float: left;
                                    margin-right: 40px;
                                    padding: 15px;

                                    .location_info_header {
                                        display: inline;
                                        padding-top: 10px;

                                        h3 {
                                            display: inline;
                                            font-size: 22px;
                                            color: $brand-blue-dark;

                                            i {
                                                color: $brand-color;
                                                font-size: 22px;
                                                padding-right: 5px;
                                            }
                                        }

                                        span {
                                            float: right;
                                            color: $brand-color;
                                            padding-right: 15px;
                                        }
                                    }

                                    .location_info_details {
                                        p {
                                            font-size: 16px;
                                            padding-left: 30px;
                                            color: $text-blue-color;
                                        }

                                        a {
                                            color: $brand-color;
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }

                            .location_banner_bottom {
                                background-color: $brand-blue-dark;
                                min-height: 250px;

                                h3 {
                                    padding-top: 15px;
                                    color: $white-color;
                                    font-size: 22px;
                                }

                                p {
                                    color: $white-color;
                                    font-size: 16px;
                                }

                                a {
                                    background: transparent;
                                    border: 1px solid $white-color;
                                    color: $white-color;
                                    margin-right: 15px;
                                    margin-top: 15px;
                                    width: 100%;

                                    &:last-child {
                                        margin-bottom: 15px;
                                    }
                                }

                                span {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    padding: 10px;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}