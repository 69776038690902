@import "../base/var";

@media (min-width: 1024px) {
    .column_advisor {
        background-color: $grey-background-color;

        .container {
            .heading-coll {
                a {
                    color: $white-color;
                    text-align: left;
                    width: 100%;
                    font-size: 26px;
                    padding: 15px;
                    position: relative;
                    padding-right: 40px;

                    i, span {
                        text-align: right;
                        float: right;
                        font-size: 25px;
                        padding: 5px;
                        position: absolute;
                        right: 15px;
                        top: 35%;
                        bottom: 50%;
                        padding-top: 0;
                    }

                    &:hover {
                        color: $white-color;
                        text-decoration: none;
                    }
                }
            }

            .advisor_header {
                background-color: $white-color;
                min-height: 98px;
                padding: 20px 0;

                .header_content_advisor {
                    float: left;

                    h2 {
                        color: $brand-color;
                        padding: 10px;
                    }

                    i, span {
                        display: none;
                    }
                }

                .uitility_content_advisor {
                    float: left;

                    div {
                        float: right;

                        .switch-side {
                            margin-top: 18px;
                            cursor: pointer;

                            .input-search {
                                display: none;

                                input {
                                    height: 28px;
                                    margin-right: 8px;
                                    color: $brand-dark-color;
                                    font-family: $muliFont;
                                    padding-left: 10px;
                                    border: none;
                                    background-color: $white-color;
                                }

                                i, span {
                                    color: $white-color;
                                }
                            }
                        }

                        a {
                            border: 1px solid;
                            margin-right: 25px;
                            font-family: $muliFont
                        }

                        span {
                            color: $white-color;
                            font-size: 16PX;
                            padding: 20px 0;

                            i, span {
                                font-size: 16px;
                                color: $white-color;
                            }
                        }
                    }
                }
            }

            .column_section_advisor {
                padding-bottom: 20px;

                .row {
                    width: 100%;
                    padding-top: 25px;

                    .advior_info_card {
                        float: left;
                        margin-bottom: 25px;

                        .advisor_info_outer {
                            background: $white-color;
                            padding: 15px;


                            .advisor_info_cta {
                                h3 {
                                    color: $brand-color;
                                    font-size: 20px;
                                }

                                p {
                                    color: $brand-blue-dark;
                                    font-size: 10px;
                                    margin-bottom: 0;
                                }

                                div {
                                    margin-top: 15px;

                                    a {
                                        width: 100%;
                                        align-self: center;
                                        text-align: center;
                                        border: none;

                                        i, span {
                                            padding-left: 10px;
                                        }

                                        &:hover {
                                            border: 1px solid;
                                        }

                                        &:last-child {
                                            border: none;

                                            &:hover {
                                                border: none;
                                            }
                                        }
                                    }
                                }
                            }

                            .advisor_imfo_img {
                                margin-bottom: 5px;

                                img {
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width:0px) and (max-width:1023px) {
    .column_advisor {
        .container {
            .heading-coll {
                a {
                    color: $white-color;
                    text-align: left;
                    width: 100%;
                    font-size: 26px;
                    padding: 15px;
                    position: relative;
                    padding-right: 45px;

                    i, span {
                        text-align: right;
                        float: right;
                        position: absolute;
                        right: 15px;
                        top: 35%;
                        bottom: 50%;
                        padding-top: 0;
                    }

                    &:hover {
                        color: $white-color;
                        text-decoration: none;
                    }
                }
            }

            .advisor_header {
                background-color: $white-color;
                min-height: 98px;
                padding: 20px 0;

                .header_content_advisor {
                    float: left;
                    padding-left: 0;

                    h2 {
                        color: $brand-color;
                    }

                    i, span {
                        display: none;
                    }
                }

                .uitility_content_advisor {
                    float: left;

                    div {
                        float: right;

                        .switch-side {
                            margin-top: 18px;
                            cursor: pointer;

                            .input-search {
                                display: none;

                                input {
                                    height: 28px;
                                    margin-right: 8px;
                                    color: $brand-dark-color;
                                    font-family: $muliFont;
                                    padding-left: 10px;
                                    border: none;
                                    background-color: $white-color;
                                }

                                i, span {
                                    color: $white-color;
                                }
                            }
                        }

                        a {
                            border: 1px solid;
                            margin-right: 25px;
                            font-family: $muliFont
                        }

                        .switch-side-inner {
                            color: $white-color;
                            font-size: 16PX;
                            padding: 20px 0;

                            i, span {
                                font-size: 16px;
                                color: $white-color;
                            }
                        }
                    }
                }
            }

            .column_section_advisor {
                padding-bottom: 20px;

                .row {
                    width: 100%;
                    padding-top: 25px;

                    .advior_info_card {
                        float: left;
                        margin-bottom: 25px;

                        .advisor_info_outer {
                            background: $white-color;
                            padding: 15px;


                            .advisor_info_cta {
                                h3 {
                                    color: $brand-color;
                                    font-size: 20px;
                                }

                                p {
                                    color: $brand-blue-dark;
                                    font-size: 10px;
                                    margin-bottom: 0;
                                }

                                div {
                                    margin-top: 15px;

                                    a {
                                        width: 100%;
                                        align-self: center;
                                        text-align: center;
                                        border: none;

                                        i, span {
                                            padding-left: 10px;
                                        }

                                        &:hover {
                                            border: 1px solid;
                                        }

                                        &:last-child {
                                            border: none;

                                            &:hover {
                                                border: none;
                                            }
                                        }
                                    }
                                }
                            }

                            .advisor_imfo_img {
                                margin-bottom: 5px;

                                img {
                                    display: block;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
