#main {
    .servicing-contact {
        padding: 80px 0 60px;
        background: $teal;
        font-size: 1.1rem;

        @media (max-width:768px) {
            padding: 0;
        }

        .gform_wrapper {
            line-height: 1.25em;
            -webkit-appearance: none;

            .control-group {
                text-align: center;

                button.btn {
                    background-color: $home-blue;
                    border: solid 2px $home-blue;
                    box-sizing: border-box;
                    color: $white;
                    font-size: 1rem;
                    padding: 12px 20px;
                    line-height: 1em;
                    cursor: pointer;
                    display: inline-block;
                    text-align: center;
                    line-height: 1em;
                    width: auto;
                    border-radius: 0;
                    font-weight: normal;
                    text-transform: none;
                    font-family: lato,sans-serif;

                    i {
                        display: none;
                    }

                    &:hover {
                        background-color: transparent;
                        color: $home-blue;
                    }
                }

                input.button:hover,
                input[type=submit]:hover {
                    background: none;
                    color: $home-blue;
                }
            }

            &.gform_legacy_markup_wrapper {
                .form-group {
                    input[type='text'] {
                        font-size: 1.1rem;
                    }
                }
            }

            .top_label select,
            textarea,
            input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
                border: none;
                max-width: none;
                font-size: 1.1rem;
                padding: 8px;
                text-indent: 0;
                min-height: 0;
                line-height: 1em;
                border-radius: 0;
                margin-left: 0;

                @media (max-width:768px) {
                    font-size: .9rem;
                }
            }

            li.hidden_label input {
                margin-top: 0;
            }
        }

        .wrapper {
            max-width: 960px;

            @media (max-width: 768px) {
                padding: 50px 30px 10px;
                width: 100%;
            }
        }

        p {
            text-align: center;
            font-weight: 700;
            font-size: .8rem;
            color: $home-blue;
            margin-bottom: 40px;
        }

        h2 {
            text-align: center;
            margin-bottom: 10px;
            color: $home-blue;
            font-family: lato,sans-serif;
            font-weight: 900;
            text-transform: none;
        }
    }

    .gform_legacy_markup_wrapper {
        margin-bottom: 16px;
        margin-top: 16px;
        max-width: 100%;

        .control-group {
            padding: 16px 0 10px 0;
            margin: 16px 0 0 0;
            clear: both;
            width: 100%;
        }

        select option {
            padding: 2px;
            display: block;
        }
        /*.gfield_radio li label {
        margin: 2px 0 0 4px;
    }*/
        /*ul.gfield_checkbox li label,
    ul.gfield_radio li label {
        display: -moz-inline-stack;
        display: inline-block;
        letter-spacing: inherit;
        vertical-align: middle;
        cursor: pointer;
    }*/
        .form-group {
            margin-left: 0 !important;
            list-style-type: none !important;
            list-style-image: none !important;
            list-style: none !important;
            overflow: visible;
        }


        form {
            text-align: left;
            max-width: 100%;
            margin: 0 auto;

            .form-group {
                margin-left: 0 !important;
                list-style-type: none !important;
                list-style-image: none !important;
                list-style: none !important;
                overflow: visible;
            }

            .form-group {
                label {
                    display: none;
                }
            }
        }


        & * {
            box-sizing: border-box !important;
        }

        & :after {
            box-sizing: border-box !important;
        }

        & :before {
            box-sizing: border-box !important;
        }

        ul {
            text-indent: 0;

            li:after {
                padding: 0;
                margin: 0;
                overflow: visible;
            }

            li:before {
                padding: 0;
                margin: 0;
                overflow: visible;
            }

            .gform_fields {
                padding: 0;
                margin: 0;
                overflow: visible;
                margin: 0 !important;
                list-style-type: none;
                display: block;
            }

            li.gfield {
                margin-top: 16px;
                padding-top: 0;
                clear: both;
            }
        }

        ul.gfield_checkbox,
        ul.gfield_radio {
            margin: 6px 0;
            padding: 0;
        }

        ul.gform_fields {
            padding: 0;
            margin: 0;
            overflow: visible;
            margin: 0 !important;
            list-style-type: none;
            display: block;

            li.gfield {
                padding-right: 16px;
            }
        }

        .gfield_checkbox li label,
        .gfield_radio li label {
            display: -moz-inline-stack;
            display: inline-block;
            padding: 0;
            width: auto;
            line-height: 1.5;
            font-size: .875em;
            vertical-align: middle;
            max-width: 85%;
            white-space: normal;
        }

        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
            font-size: inherit;
            font-family: inherit;
            padding: 5px 4px;
            letter-spacing: normal;
        }


        .gform_body {
            width: 100%;
        }

        .gform_validation_container {
            display: none !important;
            position: absolute !important;
            left: -9000px;
        }

        .hidden_label {
            .gfield_label {
                clip: rect(1px, 1px, 1px, 1px);
                position: absolute !important;
                height: 1px;
                width: 1px;
                overflow: hidden;
            }
        }

        label.gfield_label {
            font-weight: 700;
            font-size: inherit;
        }

        legend.gfield_label {
            font-weight: 700;
            font-size: inherit;
        }

        .top_label {

            .gfield_label,
            legend.gfield_label {
                display: inline-block;
                line-height: 1.3;
                clear: both;
            }
        }

        .gfield_required {
            /*color: $Color1;*/
            margin-left: 4px;
        }
    }

    .gform_validation_container {
        display: none !important;
        position: absolute !important;
        left: -9000px;
        display: inline-block;
        line-height: 1.3;
        clear: both;
    }

    html > body .entry ul {
        text-indent: 0;
    }

    body {
        .gform_legacy_markup_wrapper {

            .gform_body {
                ul.gform_fields {
                    li.gfield.gform_validation_container {
                        display: none !important;
                        position: absolute !important;
                        left: -9000px;
                    }
                }
            }

            ul {
                li.gfield {
                    margin-top: 16px;
                    padding-top: 0;
                }

                li.field_description_below div.ginput_container_checkbox,
                li.field_description_below div.ginput_container_radio {
                    margin-top: 16px;
                    margin-bottom: 0;
                }
            }

            ul.gform_fields {
                li.gfield.gform_validation_container {
                    display: none !important;
                    position: absolute !important;
                    left: -9000px;
                }
            }

            .top_label {
                div.ginput_container {
                    margin-top: 8px;
                }
            }
        }
    }

    .sr-only {
        border: 0 none;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input,
    input:focus,
    :focus {
        outline: 0;
    }
}
