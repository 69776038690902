﻿.button {
    font-weight: 500;
    font-size: 1rem;
    padding: 12px 16px;
    line-height: 1em;

    &.dark-blue {
        background-color: $home-blue;
        color: $white;
        border: solid 2px $home-blue;

        &:hover {
            background-color: transparent;
            color: $home-blue;
            border: solid 2px $home-blue;
        }
    }

    &.outline-dark {
        background-color: transparent;
        color: $home-blue;
        border: solid 2px $home-blue;

        &:hover {
            background: $home-blue;
            border: solid 2px $home-blue;
            color: $white;
        }
    }
}

.mortgage-hero-banner__inner-content {
    .button {
        @media (max-width:520px) {
            display: inline-block;
            width: calc(50% - 2px);
            margin-right: 0;
            text-align: center;
            font-size: .8rem;
        }
    }
}
