.main-content {
    position: relative;
}
.gallery_sectionsecond {
    min-height: 590px;
    @media(min-width:768px) {
        min-height: 640px;
    }
    @media(min-width:1024px) {
        min-height: 660px;
    }
}
.gallery__section {
    position: relative;
    padding-top: 40px;

    .gallery-outer {
        margin-top: 40px;

        .carousel-control-prev {
            left: 30px;
        }

        .carousel-control-next {
            right: 30px;
        }

        .btn-white {
            border-radius: 0;
            padding: 1em 2em 1em 2em;
            cursor: pointer;
            color: $brand-light-color;
            background-color: $white;
            border-color: $white;
            z-index: 9;
            opacity: 1;
            width: 78px;
            height: 78px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            i {
                font-size: 60px
            }

            &:hover {
                color: $white;
                background-color: $brand-color;
            }
        }

        .carousel-inner {

            .carousel-item {
                margin-right: auto;

                .block {
                    div {
                        position: relative;

                        img {
                            width: 100%;
                            height: 230px;
                        }

                        .how-to-hire {
                            position: absolute;
                            background-color: $white;
                            color: $brand-black-color;
                            font-family: $muliFont;
                            font-weight: 600;
                            font-size: 16px;
                            left: 0;
                            bottom: 0;
                            padding: 17px 13px 17px 13px
                        }
                    }

                    .image-details {
                        background-color: $grey-background-color;
                        padding: 20px;
                        height: auto;

                        .btn {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        @media (min-width: 768px) {
            /* show 3 items */
            .carousel-inner .active,
            .carousel-inner .active + .carousel-item,
            .carousel-inner .active + .carousel-item + .carousel-item,
            .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
                display: block;
            }

            .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
            .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
            .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item,
            .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
                transition: none;
            }

            .carousel-inner .carousel-item-next,
            .carousel-inner .carousel-item-prev {
                position: relative;
                transform: translate3d(0, 0, 0);
            }

            .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
                position: absolute;
                top: 0;
                right: -25%;
                z-index: -1;
                display: block;
                visibility: visible;
            }
            /* left or forward direction */
            .active.carousel-item-left + .carousel-item-next.carousel-item-left,
            .carousel-item-next.carousel-item-left + .carousel-item,
            .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
            .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
            .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
                position: relative;
                transform: translate3d(-100%, 0, 0);
                visibility: visible;
            }
            /* farthest right hidden item must be abso position for animations */
            .carousel-inner .carousel-item-prev.carousel-item-right {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                display: block;
                visibility: visible;
            }
            /* right or prev direction */
            .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
            .carousel-item-prev.carousel-item-right + .carousel-item,
            .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
            .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
            .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
                position: relative;
                transform: translate3d(100%, 0, 0);
                visibility: visible;
                display: block;
                visibility: visible;
            }
        }
    }
}

.gallery_sectionsecond {
    margin-top: 70px;
    margin-bottom: 70px;

    .owl-carousel {
        .owl-stage-outer {
            .owl-stage {
                display: flex;

                .owl-item {
                    display: flex;
                    flex: 1 0 auto;

                    > div {
                        cursor: pointer;
                        margin: 6% 3%;

                        @media(max-width: 767px) {
                            margin: 0;
                        }

                        transition: margin 0.4s ease;
                    }

                    .gallery_tile {
                        background-color: $grey-background-color;

                        .gallery_tile_upper {
                            .gallery_tile_upper_img {
                                position: relative;

                                img {
                                    max-height: 263px;
                                }

                                .how-to-hire {
                                    position: absolute;
                                    bottom: 0;
                                    padding: 4px 24px 4px 24px;
                                    width: auto;
                                    background: $white;
                                    color: $brand-black-color;
                                    font-weight: 700;
                                    font-size: 22px;
                                    letter-spacing: 0;
                                    font-family: 'Archivo Narrow', sans-serif;
                                }
                            }

                            .image-details {
                                background: $grey-background-color;

                                p {
                                    padding: 15px 27px;
                                    font-family: 'Muli', sans-serif;
                                    letter-spacing: 0;
                                    color: #00253E;
                                    opacity: 1;
                                    font-size: 16px;
                                }

                                center {
                                    padding-bottom: 15px;

                                    .btn {
                                        width: 85%;
                                        font-family: 'Muli', sans-serif;
                                        background-color: $brand-color;
                                        font-weight: 200;
                                        font-size: 16px;

                                        &:hover {
                                            color: $white;
                                            background: $brand-light-color;
                                        }
                                    }
                                }
                            }
                        }

                        .cta-gallery {
                            padding: 0 27px 15px 27px;

                            a {
                                width: 100%;
                                padding: 1em 2em 1em 2em;
                                border-radius: unset;
                                line-height: 1;
                            }
                        }
                    }

                    &.center {
                        > div {
                            cursor: auto;
                        }
                    }

                    &:not(.center) {
                        > div {
                            opacity: .8;
                        }

                        .gallery_tile {
                            .gallery_tile_upper {
                                .image-details {
                                    center {
                                        .btn {
                                            background-color: $grey-background-color;
                                            color: $brand-light-color;
                                            border: 1px solid $brand-light-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .owl-nav {
            width: 23%;

            @media (min-width:768px) and (max-width:1024px) {
                width: 40%;
            }

            @media (max-width:767px) {
                width: auto !important;
            }

            margin: 0 auto;
            margin-top: 10px;
            padding: 15px;
            background: $grey-background-color 0% 0% no-repeat padding-box;

            button {
                z-index: 1;
                border-radius: 0;
                padding: 1em 2em 1em 2em;
                cursor: pointer;
                opacity: 1;
                background-color: $white;
                border-color: $white;
                width: 43px;
                height: 43px;
                background-color: $white;
                color: $brand-blue-dark;
                margin: 5px 10px;
                border: 2px solid;
                position: relative;
                outline: none;

                i {
                    font-size: 22px;
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%,-50%);
                }

                &:hover {
                    background-color: $brand-blue-dark;
                    color: $white;
                }

                &.owl-prev {
                    left: 0;

                    span {
                        &:before {
                            content: "\f104";
                            font-family: Fontawesome;
                            font-size: 36px;
                            font-weight: 800;
                        }
                    }
                }

                &.owl-next {
                    right: 0;

                    span {
                        &:before {
                            content: "\f105";
                            font-family: Fontawesome;
                            font-size: 36px;
                            font-weight: 800;
                        }
                    }
                }

                span {
                    font-size: 0;
                    display: block;
                }
            }
        }
    }
}
