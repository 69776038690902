﻿/*Fonts*/

/*Font declaration*/
$muliFont: 'Muli', sans-serif;
$archivoFont:'Archivo Narrow', sans-serif;

/*Color declaration*/
$brand-color: #0073B1;
$brand-light-color: #056FB2 ;
$brand-ligher-color: #25A4E1;
$brand-dark-color: #18447D;
$brand-black-color: #00263D;
$brand-blue-dark : #15467B;
$grey-background-color: #F2F2F2;
$grey-text-color: #4D4D4D;
$dark-grey-color: #808080;
$input-color: #666666;
$white-color : #ffffff;
$text-blue-color: #00253E;
$black-color :#000000;
$sky-blue-color:#96D4E8;
$btn-grey-bg: #F7F7F7;
$blue-light: #4171A7;
$blue-light-btn: #53A6DB;
$hard-pink: #F1008D;

/*Muli font styles*/
@mixin muli-regular($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 400;
    font-size: $font-size;
}


@mixin muli-semibold($font-size:16px) {
    font-family: $muliFont;
    font-weight: 600;
    font-size: $font-size;
}

@mixin muli-bold($font-size: 16px) {
    font-family: $muliFont;
    font-weight: 700;
    font-size: $font-size;
}
/*Muli font style ends*/

/*Archivo font styles*/

@mixin archivo-regular($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 500;
    font-size: $font-size;
}


@mixin archivo-semibold($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 600;
    font-size: $font-size;
}


@mixin archivo-bold($font-size: 16px) {
    font-family: $archivoFont;
    font-weight: 700;
    font-size: $font-size;
}
/*Archivo font styles end*/



/*Color Combination For Hero banner heading text */
.brand-color-heading
{
    color: $brand-color;
}
.brand-black-color-heading{
    color:  $brand-black-color;
}

