﻿.mortgage-hero-banner {
    &__inner {
        width: 100%;
        height: 80vh;
        max-height: 750px;
        min-height: 650px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        .wrapper {
            max-width: 1175px;
        }

        &-content {
            margin: 0;
            max-width: 580px;
            background: rgba(255, 255, 255, 0.9);
            padding: 40px;

            @media(max-width: 767px) {
                padding: 25px;
            }

            h1 {
                margin-bottom: 20px;
                font-size: 2.3rem;
                color: $home-blue;
                line-height: 1.2em;
                text-transform: none;
                font-weight: 900;
                font-family: 'Lato';
            }

            p {
                color: $dark;
                margin-bottom: 35px;
            }

            a {
                margin-right: 15px;
                display: inline-block;
            }
        }
    }
}
