.alert_banner {
    display: none;
    margin: 0 auto;
    padding: 0;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 50px;
    color: $white;
    background: $alert-red;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    &__inner {
        a {
            color: $white;
            display: block;
            padding: 20px 0;
            line-height: 1.5rem;

            @media (max-width: 1024px) {
                font-size: .9rem;
            }

            @media (max-width: 768px) {
                font-size: .7rem;
                padding: 20px 25px;
                line-height: 1rem;
            }
        }
    }

    &__close {
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        &:hover {
            cursor: pointer;
        }
    }
}