﻿@import "../base/var";


@media (max-width:1023px) {

    /*.bank-account__nav {
        display: none;
    }*/

    .header {
        .container-content {
            padding-left: 0;
            padding-right: 0;
            max-width: 100%;

            nav {
                position: relative;
                display: block;

                .navbar-collapse {
                    max-width: 100%;
                    text-align: center;
                    z-index: 99;
                    background-color: $grey-background-color;
                    margin-left: 0;

                    ul {
                        position: relative;

                        li {
                            position: initial !important;

                            a {
                                background-color: $white-color;
                                margin: 5px 10px;
                                padding: 13px 0 !important;

                                &:after {
                                    content: none;
                                }

                                &:hover {
                                    background-color: $brand-dark-color;
                                    color: $white-color !important;
                                }
                            }
                        }
                    }
                }

                .nav-right-menu {
                    width: 100%;
                    background-color: $white-color;


                    .top-menu {
                        display: none;
                    }

                    .button-div {
                        width: 100%;
                        display: block;

                        a {
                            width: 50%;
                            display: inline-block;

                            &.btn-white {
                                padding: 1em;
                            }

                            &.btn-brand {
                                padding: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

    #hero_banner_slider_indicators {

        ol {
            display: none;
        }


        .carousel-inner {
            .carousel-item {
                img {
                }

                .banner-text {
                    top: 10px;
                    padding-right: 0;
                    margin: auto 0;

                    div {
                        background-color: transparent;


                        span {
                            font-size: 32px;
                            color: $white-color;
                        }

                        p {
                            font-size: 16px;
                            color: $white-color;
                        }

                        a {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    h1 {
        font-size: 26px;
    }

    #myTab {
        a {
            padding: 20px;
            font-size: 16px;
        }
    }

    .tab-content {
        margin-bottom: 1em;
        width: 100%;

        .nav-item {
            width: 100%;
        }

        a {
            span {
                &:after {
                    display: block;
                    position: absolute;
                    display: none;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 30px;
                    padding: 5px;
                    text-align: center;
                    font-size: 20px;
                    content: "\f077";
                    font-family: FontAwesome;
                    border: none;
                    vertical-align: 0.18em;
                    font-size: 11px;
                }

                span {
                    float: left;
                }

                i {
                    &.right-side-down-arrow {
                        float: right;
                        position: relative;
                        top: 5px;
                        display: block;
                    }

                    &.right-side-up-arrow {
                        float: right;
                        position: relative;
                        top: 5px;
                        display: none;
                    }
                }
            }

            &.active {
                span {
                    &:after {
                        content: "\f078";
                        font-family: FontAwesome;
                        display: none;
                    }

                    .right-side-down-arrow {
                        display: none;
                    }

                    .right-side-up-arrow {
                        display: block;
                    }
                }
            }
        }





        .nav-item {
            margin-bottom: 0.5em;

            span {
                i {
                    float: left;
                    margin-right: 15px;
                }
            }
        }
    }

    .tabContainer {
        .tabContent {
            margin-bottom: 1em;
        }
    }

    .zelle-home {
        .newsletter {

            .newsletter-inner {
                padding-left: 0;
            }
        }
    }

    .card__section {
        .card {
            /*margin-top: 30px;*/
        }
    }

    footer {
        @mixin setmargin {
            margin: 5px 10px 10px 0px;
        }

        .bottom-link {
            padding-left: 5px;

            a {
                width: 100%;
                background-color: $grey-background-color;
                @include setmargin;
                padding: 10px;

                &:first-child {
                    @include setmargin;
                }
            }

            p {
                @include setmargin;
            }

            .copyright {
                @include setmargin;
            }
        }
    }

    .model-login__section {
        .modal-content {
            margin-top: 50px;

            .row {
                .bottom-line {
                    bottom: -98px;
                    height: 85px;
                }

                .col-sm-5 {
                    background-color: $brand-dark-color;
                    padding-left: 0;
                }
            }

            .model-left {

                .top-left , .bottom-left {
                    @media(max-width:767px) {
                        padding: 15px 0;
                    }
                }

            }


            .modal-body {
            }
        }
    }
    //Css for Account Section mobile view
    .account__section {
        margin-top: 50px;

        .nav-tabs {

            .nav-link {
                border: none;
                border-radius: 0;
                padding-bottom: 0px;
                line-height: 15px;
                background-color: $grey-background-color;
            }

            .nav-item-icon {
                display: -webkit-inline-box;
                color: #000;
                padding-bottom: 10px;
                padding-right: 20px;
                padding: 15px;
                font-size: 20px;
            }

            span {
                @include muli-semibold(14px);
                color: #000;
            }

            .nav-item {
                display: inline;
                padding: 0;

                &:hover {
                    border: none;
                }

                &.active {
                    color: $brand-ligher-color;
                    border: none;

                    i, span {
                        color: $brand-ligher-color;
                    }
                }
            }
        }

        .tab-content {
            p {
                @include muli-regular(20px);
                margin-top: 35px;
            }

            .btn {
                margin-top: 30px;
            }
        }
        /*Tabs Style*/
        .mResAccordion .mResTabNav {
            display: none;
        }

        .tabNav:before, .tabNav:after {
            content: " ";
            display: table;
        }

        .tabNav:after {
            clear: both;
        }

        .tabNav {
            list-style: none;
            padding: 0;
            margin: 0;
            border: none;
        }

        .tabNav li {
            float: left;
            padding: 0;
            width: 100%;
            background-color: $grey-background-color;
            margin-bottom: 5px;
        }

        .mResTabAccordA {
            display: inline-block;
            text-decoration: none;
            padding: 8px 20px;
            color: $brand-black-color;
            background-color: $grey-background-color;
        }

        .tabNav li.active > .mResTabAccordA, .mResTabAccordA:hover, .mResTabAccordA.active {
            text-decoration: none;
        }

        .tabContainer .tabContent {
            -webkit-transition: all 500ms ease-in;
            -moz-transition: all 500ms ease-in;
            -ms-transition: all 500ms ease-in;
            -o-transition: all 500ms ease-in;
            transition: all 500ms ease-in;
        }

        .tabContainer .tabContent.mTabActive {
            opacity: 1;
            display: block;
        }

        .mResTabbing .mResAccordAnchor {
            display: none;
        }



        .mTabAnimated .tabContainer {
            position: relative;
        }

        .mTabSimple .tabContent, .mTabAnimated .hideAfterAnim {
            display: none;
        }

        .defaultState {
            position: absolute;
            left: 0;
            top: 0;
        }

        .animBlock {
            -webkit-transition: all 600ms ease-in;
            -moz-transition: all 600ms ease-in;
            -ms-transition: all 600ms ease-in;
            -o-transition: all 600ms ease-in;
            transition: all 600ms ease-in;
        }

        .mTabAnimated {
            .hideAfterAnim {
                -moz-transform: translateX(0px) translateY(0px);
                -webkit-transform: translateX(0px) translateY(0px);
                -o-transform: translateX(0px) translateY(0px);
                -ms-transform: translateX(0px) translateY(0px);
                transform: translateX(0px) translateY(0px);
            }

            .defaultState {
                -moz-transform: translateY(100px);
                -webkit-transform: translateY(100px);
                -o-transform: translateY(100px);
                -ms-transform: translateY(100px);
                transform: translateY(100px);
            }

            .mTabActive {
                -moz-transform: translateX(0);
                -webkit-transform: translateX(0);
                -o-transform: translateX(0);
                -ms-transform: translateX(0);
                transform: translateX(0);
            }
        }



        .account__section_right {
            .account__section_right_top {
                margin-bottom: 25px;


                h2 {
                    @include muli-bold;
                    color: #000;
                    display: block;
                }

                .form-control {
                    height: 53px;
                }

                .btn {
                    padding: 0.8em 1em 0.8em 1em;
                }

                .btn i {
                    font-size: 17px;
                }
            }

            .account__section_right_bottom {
                -webkit-box-shadow: 3px 3px 5px 6px $grey-background-color; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
                -moz-box-shadow: 3px 3px 5px 6px $grey-background-color; /* Firefox 3.5 - 3.6 */
                box-shadow: 3px 3px 5px 6px $grey-background-color; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
                .account__section_right_bottom_logo {
                    background-color: $brand-dark-color;
                    height: 172px;
                    position: relative;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                    }
                }

                .account__section_right_bottom_details {
                    text-align: center;
                    padding-bottom: 40px;

                    @media (max-width: 767px) {
                        background-color: $grey-background-color;
                    }

                    p {
                        @include muli-regular(17px);
                        color: $dark-grey-color;
                        padding: 25px 55px 18px 55px;
                        text-align: left;
                        line-height: 25px;
                    }

                    .btn {
                        position: relative;

                        small {
                            font-size: 60%;
                            position: absolute;
                            top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .header {
        nav {
            padding-top: 0;
            padding-bottom: 0;


            .dropdown-menu {
                -webkit-transition: 0.3s linear all;
                -moz-transition: 0.3s linear all;
                -ms-transition: 0.3s linear all;
                -o-transition: 0.3s linear all;
                transition: 0.3s linear all;
                background-color: $white-color;
                border-radius: 0;
                border: none;
                -webkit-box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
                -ms-box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
                -o-box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
                box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
                display: block;
                left: 0;
                opacity: 0;
                padding: 0;
                right: 0;
                top: 100%;
                transform-origin: 0 0;
                transform: rotateX(-75deg);
                visibility: hidden;
                width: 100%;
            }

            .dropdown {
                position: static;
            }
        }
    }
}


@media (min-wdith:0px) and (max-width:1023px) {
    .account__section .account__section_right .account__section_right_top .account__section_right_top_inner {
        padding-left: 0;
    }
}

@media (min-wdith:1024px) {
    .account__section .account__section_right .account__section_right_top .account__section_right_top_inner {
        padding-left: 3rem;
    }
}

/*@media (min-width:1024px) {
    .tab-content {
        &.tabContainer {
            > a {
                display: none;
            }
        }
    }
}*/
