﻿@import "../base/var";

.navigation-icon {
    &.bk-brand-black {
        background-color: $brand-black-color;

        ul {
            li {
                a {
                    i, span {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

@media (min-width:1024px) {
    .navigation-icon {

        ul {
            justify-content: space-between;

            li {
                border: none;
                background: transparent;
                padding: 1.75rem 2.1rem;


                a {
                    width: 100px;
                    text-align: center;

                    i, span {
                        display: block;
                        font-size: 18px;
                    }

                    .list-group-item-icon {
                        font-size: 30px;
                        display: block;
                        font-size: 33px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}

@media (min-width:0px) and (max-width:1023px) {
    .navigation-icon {

        ul {
            width: 100%;
            margin-top: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                width: 165px;
                padding: 36px 11px 11px 0;
                float: left;
                border: none;
                outline: none;
                background-color: transparent;

                @media(max-width:387px) {
                    width: 50%;
                }

                a {
                    i {
                        display: block;
                        text-align: center;
                        font-size: 33px;
                    }

                    span {
                        display: block;
                        text-align: center;
                        font-size: 18px;
                        word-break: break-word;
                    }
                }
            }
        }
    }
}

.renasantinsurance {
    .icon-cta {
        display: none;

        &:not(.cta-home) {
            ul {
                .list-group-item {
                    position: relative;

                    a {
                        &.list-group-item-img {
                            img {
                                opacity: .65;
                                -webkit-transition: opacity 150ms linear;
                                -o-transition: opacity 150ms linear;
                                transition: opacity 150ms linear;
                                -webkit-filter: grayscale(100%);
                                -moz-filter: grayscale(100%);
                                -ms-filter: grayscale(100%);
                                -o-filter: grayscale(100%);
                                filter: grayscale(100%);
                            }

                            span {
                                display: block;
                                opacity: 0;
                                position: absolute;
                                width: 100%;
                                bottom: 25px;
                                left: 0;
                                -webkit-transition: all 150ms linear;
                                -o-transition: all 150ms linear;
                                transition: all 150ms linear;
                            }

                            &:hover {
                                img {
                                    opacity: 1;
                                    -webkit-filter: grayscale(0);
                                    -moz-filter: grayscale(0);
                                    -ms-filter: grayscale(0);
                                    -o-filter: grayscale(0);
                                    filter: grayscale(0);
                                }

                                span {
                                    opacity: 1;
                                    bottom: 15px;
                                }
                            }
                        }
                    }

                    &.active {
                        background-color: transparent;
                        border: none;

                        a {
                            &.list-group-item-img {
                                img {
                                    opacity: 1;
                                    -webkit-filter: grayscale(0);
                                    -moz-filter: grayscale(0);
                                    -ms-filter: grayscale(0);
                                    -o-filter: grayscale(0);
                                    filter: grayscale(0);
                                }

                                span {
                                    opacity: 1;
                                    bottom: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .list-group {
            @media (max-width: 1024px) {
                justify-content: center;
            }
        }

        ul {
            .list-group-item {
                padding-left: 0;
                padding-right: 0;

                a {
                    &.list-group-item-img {
                        img {
                            width: 6rem;
                            max-width: 100px;
                            display: block;
                            margin: 0 auto;
                            margin-bottom: 15px;

                            @media (max-width: 800px) {
                                max-width: 90px;
                                width: 45%;
                            }
                        }
                    }
                }

                @media (max-width: 800px) {
                    margin: 3% 1%;
                    width: 23%;
                }

                @media (max-width: 520px) {
                    margin: 3% 1%;
                    width: 31.333%;
                }
            }
        }
    }
}
