﻿@import "../base/var";

@media (min-width:1024px) {
    .column_2_herobanner {
        background-color: $brand-color;
        min-height: 400px;

        .container-content {
            position: relative;

            .column_2_herobanner_left {
                float: left;
                min-height: 400px;

                .column_2_herobanner_img {
                    img {
                        padding-top: 50px;
                    }
                }
            }

            .column_2_herobanner_right {
                min-height: 400px;
                padding: 15px;
                padding-top: 50px;
                padding-bottom: 30px;

                h2 {
                    color: $sky-blue-color;
                    margin-bottom: 26px;
                }

                p {
                    color: $white-color;
                }

                h3 {
                    color: $sky-blue-color;
                }

                ul {
                    color: $white-color;
                    list-style: none;

                    li {
                        text-indent: -35px;

                        a {
                            color: $white-color;
                            padding: 0;
                            text-decoration: underline;
                        }

                        &:before {
                            content: '✓';
                            padding: 10px;
                        }
                    }
                }

                a {
                    margin-top: 20px;
                    margin-right: 20px;
                    font-weight: 300;
                    text-decoration: none;
                    padding-left: 46px;
                    padding-right: 46px;
                }
            }
        }
    }
}


@media (min-width: 0px) and (max-width:1023px) {

    .column_2_herobanner {
        background-color: $brand-color;
        min-height: 400px;

        .container-content {
            position: relative;

            .column_2_herobanner_left {
                min-height: 200px;

                .column_2_herobanner_img {
                    /*position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);*/
                    position: static;
                }
            }

            .column_2_herobanner_right {
                min-height: 400px;

                h2 {
                    color: $sky-blue-color;
                }

                p {
                    color: $white-color;
                }

                h3 {
                    color: $sky-blue-color;
                }

                ul {
                    list-style: none;
                    li {
                        color: $white-color;
                        text-indent: -35px;

                        a {
                            color: $white-color;
                            padding: 0;
                            text-decoration: underline;
                        }

                        &:before {
                            content: '✓';
                            padding: 10px;
                        }
                    }
                }

                a {
                    margin-top: 20px;
                    width: 100%;
                    margin-bottom: 20px;
                    color: $white-color;
                    font-weight: 300;
                    text-decoration: none;
                }
            }
        }
    }
}



//Style for Super script

#great-rate {
    font-size: 2rem;
    color: white;
    line-height: 1em;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    margin: 45px 0 0 0;
    position: relative;
    left: 15px;

    em {
        font-style: italic;
    }

    sup {
        font-size: 50%;
        position: relative;
        top: -6px;
    }
}