﻿@import "../base/var";

.generic-popup {
    padding-top: 20px;
    padding-bottom: 20px;

    .popup-outer {
        margin: 0 auto;

        .row {
            .popup-image {
                padding: 5px;
                background: $grey-background-color;

                .popup-img-block {
                    padding: 5px;

                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }

                .popup-detail-block {
                    padding: 10px;
                    padding-bottom: 0;

                    h4 {
                        color: $brand-color;
                        font-size: 22px;
                        font-weight: 600;
                    }

                    p {
                        color: $brand-blue-dark;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 0;
                    }
                }

                .popup-cta {
                    background-color: $white-color;

                    div {
                        padding: 15px;

                        p {
                            color: $brand-blue-dark;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 1;
                            margin-bottom: 5px;
                        }
                    }

                    a {
                        width: 100%;
                        border: none;

                        &:hover {
                            border: 1px solid;
                        }
  
                    }
                }
            }

            .popup-detail {
                background-color: $white-color;
                padding: 0;

                span {
                    background-color: $brand-color;
                    color: $white-color;
                }

                h2 {
                    padding-top: 50px;
                    color: $brand-dark-color;
                    font-size: 30px;
                    font-weight: 500;
                    padding-left: 15px;
                }

                p {
                    color: $text-blue-color;
                    font-size: 18px;
                    font-weight: 400;
                    padding: 15px;
                    padding-bottom: 0;
                    padding-top: 0;
                    line-height: 1.2;
                }
            }
        }
    }
}

.external-link-modal {
    .modal-dialog {
        top: 50%;
        left: 50%;
        position: absolute;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border: none;
        background-color: #0073B1;
        margin: 0;

        .modal-header {
            padding: 0;
            border: none;

            .close {
                top: 19px;
                right: 14px;
                background-color: #0073B1;
                z-index: 999;
                padding: 0;

                span {
                    padding: 2px 10px 5px 10px;
                    font-size: 16px;
                    background-color: #0073B1;
                    color: $white-color;
                    text-shadow: none;
                    margin-left: 0;
                    font-weight: 300;
                }
            }
        }

        .generic-popup-2 {

            .popup-outer-2 {
                margin: 0 auto;
                border: 1px solid $white-color;
                background-color: $white-color;
                @media(max-width: 1023px){
                padding: 30px 0;
                }
                padding: 30px;

                span {
                    float: right;
                    color: $white-color;
                    background: $brand-color;
                }

                h2 {
                    color: $brand-dark-color;
                    font-size: 30px;
                    font-weight: 600;
                    padding-bottom: 15px;
                }

                p {
                    color: $text-blue-color;
                    font-size: 18px;
                    line-height: 1.2;
                    padding-bottom: 15px;
                }

                a {
                    margin-right: 15px;
                }
            }
        }
    }
}


@media (max-width:1023px){

    .external-link-modal {
        .modal-dialog {
            max-width: 90%;
            width: 90%;
            top: 50%;
            left: 50%;
            position: absolute;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border: none;
            background-color: #0073B1;

            .modal-header {
                padding: 0;
                border: none;

                .close {
                    top: 19px;
                    right: 14px;
                    background-color: #0073B1;
                    z-index: 999;
                    padding: 0;

                    span {
                        padding: 2px 10px 5px 10px;
                        font-size: 16px;
                        background-color: #0073B1;
                        color: $white-color;
                        text-shadow: none;
                        margin-left: 0;
                        font-weight: 300;
                    }
                }
            }
        }
    }

}