@import "../base/var";

@media (min-width:1024px) {
    .column_3_img {
        .column_3_outer {
            .column_3_inner {
                float: left;
                padding: 50px;

                .content_upper {
                    padding: 15px;

                    h3 {
                        color: $brand-blue-dark;
                    }

                    h4 {
                        color: $brand-light-color;
                    }

                    p {
                        color: $text-blue-color;
                    }
                }

                .img_lower {
                    padding: 15px;
                }
            }
        }
    }
}


@media (min-width:0px) and (max-width:1023px) {

    .column_3_img {
        .column_3_outer {
            .column_3_inner {
                padding: 0;

                .content_upper {
                    padding: 15px;

                    h3 {
                        color: $brand-blue-dark;
                    }

                    h4 {
                        color: $brand-light-color;
                    }

                    p {
                        color: $text-blue-color;
                    }
                }

                .img_lower {
                    padding: 15px;
                    text-align: center;
                }
            }
        }
    }
}
