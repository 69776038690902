﻿
$banner-color: #28aae1;
$width: 100%;
$height: 100%;
$banner-layer: #14467a;
$min-height: 600px;

@mixin head {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin background-layer {
    padding: 20px;
    color: #fff;
    font-size: 1.15rem;
    letter-spacing: .5px;
    text-align: center;
    font-weight: 500;

    @media(max-width:800px) {
        padding: 30px 20px;
    }
}

/*@mixin list {
    padding: 0;
    position: relative;
   }*/


@mixin block {
    width: $width;
    display: inline-block;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
}

@mixin icon {
    float: left;
    width: 60px;
    color: #28aae1;
    font-size: 2rem;
    text-align: center;
}

@mixin info {
    float: left;
    width: calc(100% - 160px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;

    p {
        strong {
            margin-bottom: 2px;
            display: block;
        }
    }
}

@mixin link {
    position: absolute;
    font-size: .85rem;
    bottom: 0;
    right: 0;

    a {
        color: #28aae1;
        text-decoration: none;
    }
}

@mixin more {
    text-align: right;
    color: #14467a;

    p {
        margin: 5px 0 0 0;
    }

    .extra-link {
        @include link;
    }
}



@mixin mobile-link {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;

    &:visited &:focus {
        text-decoration: none;
        outline: 0;
        color: #28aae1;
    }
}

@mixin map($minHeight) {
    height: $height;
    min-height: $minHeight;

    @media(max-width:768px) {
        display: none;
    }

    .google-map {
        width: $width;
        height: $height;
    }
}

.hover-item {
    &:hover {
        cursor: pointer;
    }
}
