@import "../base/var";


@media (min-width : 1024px) {
    .contactus_slide {
        min-height: 300px;
        padding-top: 20px;
        padding-bottom: 20px;

        .contactus_content_block {
            margin-top: 50px;
            margin-bottom: 50px;

            h1 {
                font-size: 36px;
                color: $text-blue-color;
                padding-left: 15px;
            }

            .contactus_slide_left {
                float: left;

                h1 {
                    font-size: 36px;
                    color: $text-blue-color;
                    padding-left: 0;
                }

                h5 {
                    color: $brand-light-color;
                    font-size: 18px;
                }

                p {
                    color: $text-blue-color;
                }

                ul {
                    list-style-type: square;
                    padding-left: 20px;

                    li {
                        color: $text-blue-color;
                    }

                    li::before {
                        color: $brand-blue-dark;
                    }
                }

                a {
                    margin-top: 20px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .contactus_slide_right {
                float: left;

                svg {
                    position: absolute;
                    right: 0;
                    top: 0 ;
                    left: auto ;
                }

                h2 {
                    color: $white-color;
                    padding: 25px;
                    padding-bottom: 10px;
                    font-size: 30px;
                }

                div {
                    display: block;
                    padding-left: 25px;
                    color: $white-color;
                    font-size: 22px;
                }

                a {
                    margin-top: 20px;
                    margin-left: 25px;
                }
            }
        }
    }



    .contactus_slide_right {
        float: left;
        max-height: none;

        h2 {
            color: $white-color;
            padding: 25px;
            padding-bottom: 10px;
            font-size: 30px;
        }

        svg {
            position: absolute!important;
            right: 0!important;
            top: 0!important;
            left: auto !important;
        }

        div {
            display: block;
            padding-left: 25px;
            color: $white-color;
            font-size: 22px;

            p {
                @media(min-width:1024px) and (max-width:1330px) {
                    padding: 0 26px 0 3px;
                    font-size: 21px;
                }
            }
        }

        a {
            margin-top: 20px;
            margin-left: 25px;
            margin-bottom: 20px;
        }

        &.bg-light-blue, &.bg-dark-blue, &.bg-blue {
            svg {
                fill: $white-color;
            }
        }

        &.bg-grey, &.bg-white {
            svg {
                fill: $brand-color;
            }
        }
    }

    .cd_cards {
        &.contactus_slide_right {
            max-height: none;
            padding-left: 0;
            width: 100%;

            h2 {
                padding-right: 40px;
            }

            div {
                line-height: 18px;

                i, span {
                    float: left;
                    margin-top: 4px;
                }

                p {
                    float: left;
                    width: 84% !important;
                    padding-left: 3px;
                    line-height: 30px;
                }
            }

            svg {
                rect {
                    height: 222px;
                }
            }

            &.bg-white, &.bg-grey {
                h2 {
                    color: $brand-color;
                }

                div {
                    color: $brand-color;
                }
            }
        }
    }
}


@media (min-width:0px) and (max-width:1023px) {
    .contactus_slide {
        min-height: 300px;

        .contactus_content_block {
            padding: 0;

            .contactus_slide_left {
                float: left;
                padding: 25px;

                h1 {
                    font-size: 36px;
                    color: $text-blue-color;
                }

                p {
                    color: $text-blue-color;
                }

                ul {
                    list-style-type: square;
                    padding-left: 20px;

                    li {
                        color: $text-blue-color;
                    }

                    li::before {
                        color: $brand-blue-dark;
                    }
                }

                a {
                    margin-top: 20px;
                    padding-left: 20px;
                    padding-right: 20px;
                    width: 100%;
                }
            }

            .contactus_slide_right {
                float: left;
                padding: 25px;

                h2 {
                    color: $white-color;
                    font-size: 30px;
                }

                div {
                    display: block;
                    color: $white-color;
                    font-size: 22px;
                }

                a {
                    margin-top: 20px;
                    width: 100%;
                    color: $white-color;
                }

                svg {
                    display: none;
                }
            }
        }
    }

    .contactus_slide_right {
        float: left;
        padding: 25px;
        max-height: none;

        h2 {
            color: $white-color;
            font-size: 30px;
        }

        svg {
            display: none;
        }

        div {
            display: block;
            color: $white-color;
            font-size: 22px;
            line-height: 1;

            i, span {
                float: left;
            }

            p {
                overflow-wrap: break-word;
                padding-left: 30px;
            }
        }

        a {
            margin-top: 20px;
            width: auto;
            color: $white-color;
            margin-bottom: 20px;
        }
    }
}
