.officer-finder {
    max-width: 960px;
    margin: 0 auto;
    padding: 30px 15px;
    display: table;

    &_header {
        h1 {
            text-align: left;
            text-transform: none;
            color: #00253d;
            border: none;
            padding: 0;
            margin: 50px 0 25px;
            font-size: 2.4rem;
            font-weight: 700;
            font-family: $opensans;
        }
    }

    &_header-body {
        font-size: 1rem;
        line-height: 1.5em;
        margin-bottom: 25px;
    }

    .officer-profile {
        padding: 0;
        border: solid 1px #ccc;
        margin: 20px auto 0;
    }

    .officer-details {
        width: 100%;
        margin: 0;

        &_header {
            background: #00253d;
            padding: 12px 15px;
            color: $white;
            display: flex;
            align-items: center;

            &-left {
                width: 50%;

                h1 {
                    margin: 0;
                    line-height: 1;

                    a {
                        color: $white;
                        text-transform: uppercase;
                        font-size: 1.1rem;
                        line-height: 1.3em;
                        letter-spacing: .03em;
                        margin: 0 0 2px;
                        display: block;
                        font-weight: 700;

                        @media(max-width:768px) {
                            font-size: .9rem;
                        }
                    }
                }

                .officer-job-title {
                    font-size: .85rem;
                }
            }

            &-right {
                width: 50%;
                text-align: right;
            }
        }

        &_inner {
            width: 100%;
            padding: 20px;
            float: none;

            &-content {
                width: calc(100% - 350px);
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 20px 0 30px 8%;
                float: left;

                @media (max-width: 768px) {
                    width: calc(100% - 140px);
                    padding: 12px 0 20px 7%;
                    font-size: .8rem;
                }

                > div {
                    font-size: 18px;
                    margin-bottom: 2px;

                    span {
                        font-weight: bold;
                    }

                    &.officer-email {
                        a {
                            @media (max-width: 540px) {
                                word-break: break-word;
                            }
                        }
                    }
                }
            }
        }

        &_img {
            display: inline-block;
            vertical-align: top;
            width: 200px;
            height: 250px;
            background: top center no-repeat;
            background-size: cover;
            float: left;

            @media (max-width: 1250px) {
                width: 185px;
                height: 225px;
            }

            @media(max-width:1024px) {
                width: 175px;
            }

            @media(max-width:768px) {
                width: 140px;
                height: 200px;
            }
        }

        &_more {
            background: #f3f3f3;
            border-top: solid 1px #ccc;
            text-align: right;

            a {
                display: inline-block;
                padding: 12px 25px;
                border-left: solid 1px #ccc;
                font-size: .75rem;
                color: #888;
            }
        }

        .schedule-call-link {
            margin: 30px 0 0 0;
            font-size: .9rem;
        }
    }
}

.back-to-officer-search {
    margin-top: 40px;

    a {
        display: inline-block;
        color: #888;
        font-size: .75rem;
        margin-bottom: 30px;

        i {
            margin-right: 5px;
        }
    }
}
