﻿/*Global variables*/
@import "./base/var.scss";

/*Common Tags*/
@import "./base/common-tags";
/*Common Tags Ends*/

@import "./base/heading-style";

@import "./base/background-color";

/*Add Styling and animation css for text*/

@import "./base/aos";

/*Form Tags*/
@import "./componentes/form";
/*Form Tags Ends*/

/*Buttons*/
@import "./base/buttons";
/*Button Ends*/


/*Header Mega Menu*/
@import "./componentes/header";
/*Mega Menu Ends*/

/*carousel*/
@import "./componentes/banner-carousel";
/*carousel ends*/


/*gallery carousel*/
@import "./componentes/GalleryCarousel";


/*gallery carousel ends*/
// Two Column Home Page
@import "./componentes/2_column";


@import "./componentes/hero_banner";

@import "./componentes/alternative_callout2-2";

@import "./componentes/column_advisor";

@import "./componentes/location_finder";

@import "./componentes/contactus_tile";

@import "./componentes/4columnwithhead";

@import "./componentes/hero_banner_center";

@import "./componentes/vartical_tab_rt";

@import "./componentes/2_column_herobanner";

@import "./componentes/column_callout_6_4";

@import "./componentes/column_3_img";

@import "./componentes/history_slider";

@import "./componentes/core_value";


@import "./componentes/media-queries";

@import "./componentes/feature_grid.scss";

@import "./componentes/spirit_card.scss";


@import "./componentes/navigationicon.scss";


@import "./componentes/generic_popup.scss";

@import "./componentes/stripe.scss";

@import "./componentes/search.scss";

@import "./componentes/simple_text.scss";

@import "componentes/tertiary.scss";

@import "componentes/featured_demo.scss";

@import "componentes/contactcard.scss";

@import "overrides/insurancesite.scss";


body {
    position: relative;
}


/* Global List style setting*/







/*Home page tabs*/
.account__section {
    margin-top: 70px;
    margin-bottom: 70px;
    /*.col-lg-7, .col-lg-5, .col-lg-10 {
        @media(max-width:1023px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }*/
    @media(min-width:768px)and (max-width:991px) {
        .container {
            .row {
                .left-part, .right-part {
                    max-width: 50%;
                }

                @media(max-width:767px) {
                    .right-part {
                        padding: 0;
                    }
                }

                .account__section_left, .account__section_right {
                    width: 100%;
                }
            }
        }
    }

    .account__section_left {
        h1 {
            @media(min-width:1024px) {
                margin-top: 33px;
            }
        }

        .bank-account__nav {
            @media(max-width:1023px) {
                display: none;
            }
        }

        .tabContainer {
            .nav-item {
                @media(max-width:1023px) {
                    display: inline-block;
                }

                span {
                    .nav-item-icon {
                        @media(max-width:1023px) {
                            position: relative;
                            top: 4px;
                        }
                    }
                }
            }
        }
    }

    .nav-tabs {
        .active {
            color: $brand-color;
            border: none;

            .nav-item-icon, i, span {
                color: $brand-color;
            }
        }

        .nav-link {
            border: none;
            border-radius: 0;
            background-color: #fff;
            padding-bottom: 0px;
            width: 90px;
            line-height: 15px;
            margin-bottom: 5px;
            padding-left: 0;
            color: $black-color;

            > div {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .nav-item-icon {
            display: block;
            font-size: 38px;
            color: #000;
            padding-bottom: 2px;
        }

        span {
            @include muli-semibold(14px);
            color: #000;
            margin-top: auto;
        }

        .nav-item {

            &:hover {
                border: none;
            }
        }
    }

    .tab-content {
        p {
            @include muli-regular(20px);
            margin-top: 35px;

            @media(max-width:1023px) {
                margin-top: 0;
                font-size: 17px;
            }
        }

        .btn {
            margin-top: 30px;
        }
    }
    /*Tabs Style*/
    .mResAccordion .mResTabNav {
        display: none;
    }
    /*.tabNav:before, .tabNav:after {
        content: " ";
        display: table;
    }*/
    .tabNav:after {
        clear: both;
    }

    .tabNav {
        list-style: none;
        padding: 0;
        margin: 0;
        justify-content: space-between;
        border-bottom: 3px solid #dee2e6;
        padding-bottom: 15px;
    }

    .tabNav li {
        float: left;
        padding: 0
    }

    .mResTabAccordA {
        display: inline-block;
        text-decoration: none;
        padding: 8px 20px;

        @media(max-width: 1023px) {
            padding: 13px 20px;
            height: 52px;
        }

        color: $tab-color;
        background-color: $grey-background-color;
    }

    .tabNav li.active > .mResTabAccordA, .mResTabAccordA:hover, .mResTabAccordA.active {
        text-decoration: none;
    }

    .tabContainer .tabContent {
        -webkit-transition: all 500ms ease-in;
        -moz-transition: all 500ms ease-in;
        -ms-transition: all 500ms ease-in;
        -o-transition: all 500ms ease-in;
        transition: all 500ms ease-in;
    }

    .tabContainer .tabContent.mTabActive {
        opacity: 1;
        display: block;
    }

    .mResTabbing .mResAccordAnchor {
        display: none;
    }



    .mTabAnimated .tabContainer {
        position: relative;
    }

    .mTabSimple .tabContent, .mTabAnimated .hideAfterAnim {
        display: none;
    }

    .defaultState {
        position: absolute;
        left: 0;
        top: 0;
    }

    .animBlock {
        -webkit-transition: all 600ms ease-in;
        -moz-transition: all 600ms ease-in;
        -ms-transition: all 600ms ease-in;
        -o-transition: all 600ms ease-in;
        transition: all 600ms ease-in;
    }

    .mTabAnimated {
        .hideAfterAnim {
            -moz-transform: translateX(0px) translateY(0px);
            -webkit-transform: translateX(0px) translateY(0px);
            -o-transform: translateX(0px) translateY(0px);
            -ms-transform: translateX(0px) translateY(0px);
            transform: translateX(0px) translateY(0px);
        }

        .defaultState {
            -moz-transform: translateY(100px);
            -webkit-transform: translateY(100px);
            -o-transform: translateY(100px);
            -ms-transform: translateY(100px);
            transform: translateY(100px);
        }

        .mTabActive {
            -moz-transform: translateX(0);
            -webkit-transform: translateX(0);
            -o-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
    }



    .account__section_right {
        .account__section_right_top {
            margin-bottom: 25px;
            width: 75%;
            margin-left: 25%;

            @media (max-width:767px) {
                padding: 0 15px;
            }

            @media(max-width:1023px) {
                width: 100%;
                margin-left: 0;
            }

            .account__section_right_top_inner {
            }

            h2 {
                @include muli-bold;
                color: #000;
                display: block;
            }

            .form-control {
                height: 53px;
            }

            .btn {
                padding: 0.8em 1em 0.8em 1em;
            }

            .btn i {
                font-size: 17px;
            }
        }

        .account__section_right_bottom {
            -webkit-box-shadow: 3px 3px 5px 3px $grey-background-color; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
            -moz-box-shadow: 3px 3px 5px 3px $grey-background-color; /* Firefox 3.5 - 3.6 */
            box-shadow: 3px 3px 5px 3px $grey-background-color; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
            width: 82%;
            float: right;

            @media(max-width:1023px) {
                width: 100%;
            }

            .account__section_right_bottom_logo {
                background-color: $brand-dark-color;
                height: 172px;
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    max-height: 180px;
                }
            }

            .account__section_right_bottom_details {
                text-align: center;
                padding-top: 25px;
                padding-bottom: 40px;

                p {
                    @include muli-regular(17px);
                    color: $dark-grey-color;
                    padding: 25px 55px 18px 55px;
                    text-align: left;
                    line-height: 25px;
                }

                .btn {
                    position: relative;

                    small {
                        font-size: 60%;
                        position: absolute;
                        top: 20px;
                    }
                }
            }
        }
    }
}


@import "./componentes/footer";

.modal-backdrop {
    &.show {
        background: rgba(15,68,125,.9);
    }
}


.modal-dialog {
    @media(max-width:767px) {
        max-width: none;
        margin: 15px;
    }

    @media(min-width:768px) {
        max-width: 600px;
        margin: 1.75rem auto;
    }

    @media(min-width:992px) {
        max-width: 800px;
    }
}

.Search-model {
    max-width: none;
}

.modal-content {
    background-color: $grey-background-color;

    &.search {
        padding-left: 62px;
        padding-bottom: 50px;
        margin: 0 auto;
        width: 60%;

        .modal-header {
            border-bottom: none;
        }

        .modal-body {
            background-color: $grey-background-color;

            .form-control {
                width: 65%;
                background-color: $white-color;
                border-bottom: none;
            }

            .btn-brand {
                width: auto;
                padding: 12px 50px;
                margin-top: 20px;
                background-color: $brand-blue-dark;

                &:hover {
                    color: $white-color;
                }

                span {
                    background: none;
                    @include muli-regular(16px);
                    font-weight: 100;

                    &:hover {
                        color: $white-color;
                    }
                }
            }
        }
    }

    &.BioPopupactive {
        width: 60%;
        margin: 0 auto;

        @media (max-width: 767px) {
            width: 80%;
        }

        .close {
            background-color: #0073b1;
            top: 0;
            right: 0;
            width: auto;
            z-index: 999;
            padding: 15px;

            span {
                background: none;
                @include muli-regular(16px);
                font-weight: 100;
                color: $white-color;
                margin-left: 0;
                padding: 0 5px;
            }
        }
    }


    .row {
        position: relative;
        margin-right: 0;
        margin-left: 0;
        min-height: 490px;

        .col-md-7 {
            padding: 0;
        }

        .bottom-line {
            position: absolute;
            bottom: -30px;
            width: 100%;
            color: $white-color;
            text-align: center;
            @include muli-regular(16px);

            @media(max-width:1023px) {
                bottom: -90px;
            }
        }
    }

    .col-sm-5 {
        margin: 0;
        padding-right: 0;
        padding-left: 0;
    }

    .col-sm-7 {
        margin: 0;
        padding-left: 0;
        padding-right: 0;

        .modal-footer {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            padding-top: 12px;
        }
    }

    .model-left {
        height: 100%;
        padding-left: 10px;
        padding-right: 10px;

        @media (min-width:767px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .top-left {

            @media (min-width:768px) {
                margin-bottom: 50px;
            }


            img {
                width: 100%;
                height: auto;
            }


            span {
                @include archivo-bold(20px);
                color: $brand-black-color;
                display: block;
            }

            p {
                @include muli-regular(14px);
                color: $brand-black-color;
                margin-top: 9px;
            }
        }

        .bottom-left {
            position: relative;
            padding: 10px;

            &:before {
                content: '';
                background: #f2f2f2;
                width: 100%;
                height: 100%;
                top: 0;
                left: -6px;
                z-index: 1;
                position: absolute;
                display: block;

                @media (max-width:768px) {
                    display: none;
                }
            }

            * {
                position: relative;
                z-index: 2;
            }

            span {
                @include muli-bold(14px);
                color: $brand-dark-color;
            }

            p {
                @include muli-regular(14px);
                color: $brand-black-color;
                margin-top: 9px;

                @media(max-width:1023px) {
                    padding-bottom: 10px;
                }
            }

            svg {
                position: absolute;
                top: -21px;
                left: -27px;
                z-index: 0;
                height: 137%;

                @media (max-width:768px) {
                    display: none;
                }
            }
        }
    }

    .form-horizontal {
        .btn {
            width: 100%;
        }
    }

    .tab-pane {
        padding: 25px;
    }


    .close {
        position: absolute;
        top: -45px;
        right: 15px;
        opacity: 1;
        color: $white-color;
        @include muli-regular(13px);

        span {
            color: #000;
            background: $white-color;
            padding: 2px 10px 5px 10px;
            font-size: 16px;
            font-weight: bold;
            margin-left: 15px;
        }

        &.Search-model-btn {
            background-color: #0073b1;
            top: 16px;
            right: 16px;
            width: auto;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 0;

            span {
                color: $white-color;
                background: none;
                @include muli-regular(18px);
                margin-left: 15px;
                padding: 0;
                font-weight: 100;
            }
        }
    }

    .Search-model-heading {
        color: $brand-blue-dark;
        font-family: $archivoFont;
        font-size: 32px;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .bs-example-tabs {
        div {
            border-bottom: none;


            a {
                color: $white-color;
                text-decoration: none;
                background-color: $brand-light-color;
                @include muli-semibold(19px);
                padding: 19px 42px 19px 37px;
                width: 50%;
                text-align: center;
                height: 63px;


                &:hover {
                }

                &.active {
                    background-color: $white-color;
                    color: $brand-light-color;
                }
            }
        }
    }

    .modal-footer {
        padding: 0;
        height: 63px;
        background-color: $grey-background-color;

        a {
            width: 100%;
            text-align: center;
            color: $dark-grey-color;
            @include muli-semibold(19px);
        }
    }

    span.select-wrap {
        overflow: hidden;
        position: relative;
        display: block;


        &:before {
            color: $white-color;
            position: absolute;
            right: 0;
            top: 9px;
            z-index: 7;
            pointer-events: none;
            background-color: #0073B1;
            text-align: center;
            height: 100%;
            width: 50px;
            padding: 15px;
        }

        select {
            @include muli-regular;
        }
    }

    .forgot-btn {
        @include muli-semibold(13px);
        font-style: italic;
        color: #999999;
    }

    .modal-body {
        height: 71%;
        background-color: $white-color;
    }
}

/*Generic Section CSS*/
.card__section {
    background-color: $grey-background-color;
    padding: 50px 0;

    &-heading {
        color: $brand-dark-color;
    }

    .row {
        &.two-column {
            justify-content: center;

            .card__section-data {
                width: 50%;
                flex: 0 0 50%;
                max-width: none;
                margin-bottom: 2rem !important;
            }
        }
    }

    .card {
        background-color: $white-color;
        height: 100%;

        .card_section_img {
            img {
                width: 100%;
            }
        }

        .card_section_content {
            width: 100%;

            .iconwithbg {
                min-height: 92px;

                i, span {
                    font-size: 51px;
                    display: block;
                    text-align: center;
                    vertical-align: middle;
                    padding: 21px 0 20px 0;
                }
            }

            h3 {
                color: $brand-dark-color;
                display: block;
            }

            h4 {
                color: $brand-color;
            }

            p {
                color: $brand-black-color;
                @include muli-regular(16px);
            }

            .btn {
                display: block;
                width: 100%;
                margin-bottom: 10px;
                border-radius: 0 !important;
                padding: 16px 15px !important;
                font-weight: 600 !important;

                &:focus {
                    outline: none;
                    color: $white-color;
                    box-shadow: none;
                }

                &.btn-secondry-a {
                    &:focus {
                        color: $white;
                    }

                    &:visited {
                        color: $white;
                    }

                    &:hover {
                        color: $brand-blue-dark;
                    }
                }
            }

            a {
                text-align: center;
                display: block;
                text-align: -webkit-center;
                margin-bottom: 10px;
                padding-left: 15px;
                padding-right: 15px;
            }

            .card-text {
                padding: 1.4rem 1rem 1rem !important;
            }

            .cta-card {
                padding: 1.4rem 1rem 1rem !important;
            }
        }
    }
}

.warning {
    border: 1px solid $red;
}

.tabbing {
    .bank-account__nav {
        .tabNav {
            li {
                a {
                    &.nav-item {
                    }

                    .right-side-down-arrow {
                        @media(min-width:1024px) {
                            display: none;
                        }
                    }

                    .right-side-up-arrow {
                        @media(min-width:1024px) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.vert-center {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.no-link {
    pointer-events: none;
}

.brand-blue-color {
    color: #15467B;
}
