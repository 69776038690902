﻿.simple-text {
    .quote {
        font-size: 1.5rem;
        font-weight: bold;
        color: #4bacc6;
        margin-left: -.7rem;
    }

    &.text-center {
        .m-0 {
            display: block;
            text-align: center;
        }

        .p-0 {
            margin: 0 auto;
            text-align: justify;
            float: none !important;
        }
    }
}

@media (min-width:1024px) {
    .simple-text {
        padding: 50px 0;

        h2 {
            width: 100%;
        }

        p {
            font-size: 16px;
        }

        ul {
            list-style: disc;
        }

        a {
            margin-right: 15px;
        }
    }
}

@media (min-width:0px) and (max-width:1023px) {
    .simple-text {
        padding-top: 15px;
        padding-bottom: 15px;

        h2 {
            width: 100%;
        }

        p {
            font-size: 16px;
        }

        ul {
            list-style: disc;
        }

        a {
            margin-right: 15px;
        }
    }
}
