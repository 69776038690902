.account__section {
    min-height: 730px;

    @media(min-width:768px) {
        min-height: 643px;
    }
    @media(min-width:1024px) {
        min-height: 338px;
    }
}
