@import "../base/var";


@media (min-width:1024px) {

    .hero_banner_center {

        .hero_banner_center_outer {
            min-height: 500px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 500px;

            .mobile-bg-img {
                display: none;
            }

            &.mobile {
                display: none;
            }

            &.desktop {
                display: block;
            }

            .hero_banner_center_inner {
                /*width: max-content;*/
                /*position: absolute;
                top: 50%;
                left: 50%;
                right: 50%;
                transform: translate(-50%, -50%);*/
                .hero_banner_center_inner_img {
                    text-align: center;

                    img {
                    }

                    .mobile-img {
                        display: none;
                    }

                    .desktop-img {
                        display: block;
                    }
                }

                .hero_banner_center_inner_cta {
                    width: 50%;
                    margin: 0 auto;
                    margin-top: 50px;

                    a {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (min-width: 0px) and (max-width:1023px) {
    .hero_banner_center {

        .hero_banner_center_outer {
            min-height: 450px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            height: 450px;

            &.mobile {
                display: block;
            }

            &.desktop {
                display: none;
            }

            &.no-overlay-img {
                background-image: none !important;
                min-height: unset;
                height: auto !important;
            }

            &.overlay-img {
                height: 385px !important;
                min-height: 385px !important;

                @media (max-width:767px) {
                    height: 220px !important;
                    min-height: 220px !important;
                }
            }

            .hero_banner_center_inner {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                right: 0;
                bottom: auto;
                transform: translate(-50%,-50%);

                .hero_banner_center_inner_img {
                    text-align: center;
                    padding: 15px;

                    img {
                        max-width: 100%;
                        height: auto;
                    }

                    .mobile-img {
                        display: block;
                        margin: 0 auto;
                    }

                    .desktop-img {
                        display: none;
                    }
                }

                .hero_banner_center_inner_cta {
                    margin-top: 20px;
                    text-align: center;
                }
            }
        }
    }
}
