﻿@import "../base/var";

.featured-demo {
    padding: 40px 0;

    &__card {
        text-align: center;

        &-inner {
            margin: 40px 25px;
        }

        &-heading {
            padding: 0 0 30px;
            border-bottom: 2px solid $grey-background-color;
            margin: 0 0 25px;
        }

        ul {
            padding: 0;
            list-style: none;

            li {
                margin-top: 20px;

                a {
                    font-size: 18px;
                    line-height: 22px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.player-popup {
    button {
        &.close {
            z-index: 999999;
            font-size: 28px;
            top: 0;
        }
    }

    .modal-dialog {
        height: 92%;
    }

    .modal-content {
        height: 100%;
        overflow-y: auto;
    }
}

#demo-info {
    height: auto;
}

@media (min-width:1024px) {
}


@media (min-width: 0px) and (max-width:1023px) {
    .featured-demo {
        padding: 20px 0;

        &__card {
            &--inner {
                margin: 20px 0;
            }
        }
    }

    .player-popup {
        padding-right: 0 !important;

        #scene-stage {
            width: 100%;
        }
    }
}

#player {
    width: 100%;
}
