﻿@import "../base/var";

body {
}

@media (min-width:1024px) {

    .Spirit_Card {
        padding-top: 20px;

        .container-fluid {
            .container {
                padding-top: 15px;

                .row {
                    padding-bottom: 25px;

                    .col-lg-10 {
                        margin-bottom: 15px;
                        margin-top: 15px;

                        h2 {
                            margin-bottom: 20px;
                        }

                        a {
                            margin-top: 20px;
                        }
                    }
                }

                .Spirit_Card_head {
                    padding: 15px;
                    padding-left: 0;

                    h2 {
                        color: $text-blue-color;
                        font-size: 30px;
                    }

                    p {
                        color: $text-blue-color;
                        @include muli-regular(16px);
                    }
                }

                .Spirit_Card_Content {
                    padding: 15px;

                    .Spirit_Card_img {
                        padding: 15px;
                    }

                    .Spirit_Card_text {
                        padding-top: 5px;
                        padding-right: 30px;

                        p {
                            color: $text-blue-color;
                            font-size: 16px;
                        }
                    }
                }

                .Spirit_Card_bottom_text {
                    padding: 15px;
                    padding-left: 0px;

                    p {
                        color: $text-blue-color;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .Contact_Form {
        &.Spirit_Card {
            .form-horizontal {
                .form-group {
                    label {
                        font-size: 18px;
                        color: $brand-dark-color;
                    }

                    .form-control {
                        border-radius: 9px;
                        border: 1px solid $brand-color;
                    }
                }

                .control-group {
                    .controls {
                        .btn-brand {
                            border-radius: 11px;

                            i {
                                &:hover {
                                    color: $brand-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .Spirit_Card_head {
        margin-bottom: 40px;

        h2 {
            margin-bottom: 28px;
        }
    }

    .Spirit_Card_Content {
        margin: 20px 0;

        > div {
            margin-bottom: 40px;
            padding-left: 0;
            padding-right: 0;

            &:nth-child(2n) {
                margin-left: 10%;
            }

            .Spirit_Card_img {
                text-align: left;

                img {
                    padding: 10px 25px;
                }
            }

            .Spirit_Card_text {
                width: auto;
                margin: 0 auto;

                p {
                    font-size: 16px;
                    text-align: left;
                }
            }
        }
    }
}

@media (min-width:0px) and (max-width:1023px) {

    .Spirit_Card {
        .container-fluid {
            .container {
                padding-top: 15px;
                padding-left: 0;
                padding-right: 0;

                @media(max-width:767px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .row {
                    padding-bottom: 25px;

                    > div {
                        a {
                            margin-top: 25px;
                        }
                    }
                }

                .Spirit_Card_head {
                    padding: 15px;

                    h2 {
                        color: $text-blue-color;
                        font-size: 30px;
                    }

                    p {
                        color: $text-blue-color;
                        @include muli-regular(16px);
                    }
                }

                .Spirit_Card_Content {
                    padding: 15px;

                    .Spirit_Card_img {
                        padding: 15px;

                        img {
                            display: block;
                            align-self: center;
                            margin: 0 auto;
                        }
                    }

                    .Spirit_Card_text {
                        padding-top: 5px;
                        padding: 15px;

                        p {
                            color: $text-blue-color;
                            font-size: 16px;
                            text-align: center;
                        }
                    }
                }

                .Spirit_Card_bottom_text {
                    padding: 15px;

                    p {
                        color: $text-blue-color;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .Spirit_Card_head {

        h2 {
            margin-bottom: 28px;
        }
    }

    .Spirit_Card_Content {
        margin: 20px 0;

        > div {
            margin-bottom: 40px;

            &:nth-child(2n) {
                margin-left: 10%;
            }

            @media(max-width:767px) {
                margin-bottom: 10px;

                &:nth-child(2n) {
                    margin-left: 0;
                }
            }


            .Spirit_Card_img {
                text-align: left;
            }

            .Spirit_Card_text {
                width: auto;
                margin: 0 auto;

                p {
                    font-size: 16px;
                    text-align: left;
                }
            }
        }
    }
}
