﻿@import "../base/var";

@media (min-width:1024px) {
    footer {
        margin-top: 90px;

        .container {
            padding: 0;
        }

        .footer-title {
            color: $brand-color;
            @include muli-bold(18px);
        }

        .row {
            margin: 0;

            &:nth-child(2n) {
                margin-top: 82px;
            }

            div {
                a {
                    display: block;
                    color: $brand-dark-color;
                }
            }

            .bottom-link {
                a {
                    color: $brand-color;
                    display: inline-block;
                }
            }
        }

        .bottom-link {
            padding: 15px;

            a {
                color: $brand-ligher-color;
                @include muli-bold(18px);
                margin-left: 3rem;

                &:first-child {
                    margin-left: 0;
                }
            }

            p {
                color: black;
                margin-top: 15px;
                font-weight: 700;

                @media(min-width:767px) and (max-widht:1023px) {
                    width: 80%;
                }

                float: left;
                width: 80%;
            }

            .copyright {
                margin-bottom: 50px;
                float: left;
                width: 100%;
                padding-left: 0;

                span {
                    @include muli-bold(18px);
                    color: black;
                }

                img {
                    float: right;
                }
            }
        }

        .social-media {
            background-color: $grey-background-color;
            text-align: center;
            padding: 30px 0 30px 0;
            width: 100%;

            span {
                @include archivo-bold(27px);
                color: $input-color;
                text-align: center;
                width: 100%;
            }

            a {
                color: $brand-black-color;
                font-size: 22px;
                padding-left: 10px;
                padding-right: 10px;

                &:hover {
                    color: $brand-color;
                    text-decoration: none;
                }
            }

            div {
                width: 100%;
                text-align: center;
                margin-top: 10px;
            }
        }
    }
}



@media (min-width:0px) and (max-width:1023px) {
    footer {

        .container {
            padding: 0;
        }

        .footer-title {
            color: $brand-ligher-color;
            @include muli-bold(18px);
        }

        .row {
            margin: 0;

            div {
                padding-bottom: 25px;
                padding: 15px;

                a {
                    display: block;
                    color: $brand-dark-color;
                }
            }
        }

        .row {
            .bottom-link {
                padding-top: 0;

                a {
                    @include muli-bold(18px);
                    margin-left: 0;
                    display: inline-block;
                    padding: 0;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                p {
                    color: $dark-grey-color;
                    margin-top: 15px;

                    @media(min-width:767px) and (max-width:1023px) {
                        width: 80%;
                    }
                }

                .copyright {
                    padding-left: 0;

                    span {
                        @include muli-bold(18px);
                        color: $dark-grey-color;
                    }

                    img {
                        float: right;
                        height: 70px !important;
                        width: 150px !important;
                    }

                    div {
                        padding: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }

        .social-media {
            background-color: $grey-background-color;
            text-align: center;
            padding: 30px 0 30px 0;
            width: 100%;

            span {
                @include archivo-bold(27px);
                color: $input-color;
                text-align: center;
                width: 100%;
            }

            a {
                color: $brand-black-color;
                font-size: 22px;
                padding: 0 10px;

                &:hover {
                    color: $brand-color;
                }
            }

            div {
                width: 100%;
                text-align: center;
                padding-top: 5px;
            }
        }
    }
}
