.generic-popup {
    .modal {
        z-index: 100000;
    }

    .modal-close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9;

        button {
            background: none;

            span {
                color: black;
                font-size: 20px;
            }
        }
    }

    iframe {
        width: 100%;
        height: 592px;
        overflow: hidden;

        html {
            overflow: hidden;
        }
    }

    .modal-inner {
    }
}

.nation {
    .modal-backdrop {
        z-index: 99999;
    }
}

.open-generic-modal {
    &:hover {
        cursor: pointer;
    }
}
