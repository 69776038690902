// https://stackoverflow.com/questions/67655096/bootstrap-5-form-group-form-row-form-inline-not-working

.form-group {
    margin-bottom: 1rem;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row > .col {
    padding-left: 5px;
    padding-right: 5px;
}

.form-group label {
    margin-bottom: 0.5rem;
}