﻿@import "var";

ul {
    margin: 0;
}

h1 {
    color: $brand-black-color;
    @include archivo-bold(36px);
}

h2 {
    color: $brand-black-color;
    @include archivo-bold(30px);
}

h3 {
    color: $brand-color;
    @include archivo-bold(26px);
}

h4 {
    color: $brand-black-color;
    @include archivo-bold(22px);
}

h5 {
    color: $brand-black-color;
    @include archivo-bold(20px);
}

h6 {
    color: $brand-black-color;
    @include archivo-bold(16px);
}

body {
    font-family: "Muli",sans-serif;
}


.clearfix:after,
.clearfix:before {
    content: '';
    display: table
}

.clearfix:after {
    clear: both;
    display: block
}

a {
    color: $brand-color;
    @include muli-semibold;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

section {
}


/*Model popup*/
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: $brand-dark-color;

    &.show {
        opacity: 1;
    }
}

    .container {
        max-width: 1364px;
    }
    .container-content{
        max-width: 1200px;
        margin: 0 auto;
        padding-left: 15px;
        padding-right: 15px;
    }
.container-1800 {
    max-width: 1800px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

//list style type square

