@import "../base/var";

.vartical_tab_outer {
    padding-left: 30px;
    padding-right: 30px;

    @media(min-width:1024px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media (min-width:768px) {
    .vartical_tab, .tertiary-nav {
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;

        .filled_color_blue {
            /*height: 100%;
            width: 25%;
            background-color: $brand-color;
            position: absolute;
            top: 212px;
            max-height: 82px;
            left: 0;*/
            @media(min-width:1024px) and (max-width: 1386px) {
                top: 230px;
                left: 0;
            }

            svg {
                left: 50%;
                position: absolute;
                top: -25px;

                @media(min-width:1024px) and (max-width: 1199px) {
                    left: 27%;
                }

                @media(min-width:1200px) and (max-width: 1540px) {
                    left: 17%;
                }
            }
        }

        .vartical_tab_outer {
            margin-top: 50px;

            @include ie-only {
                flex: 1 1 100%;
            }


            .tabpanel_coustom {
                > div {
                    display: block;
                }

                .tabpanel_header {
                    padding: 20px 0;
                    min-height: 152px;
                }

                .nav-pills {
                    @media(min-width:767px) and (max-width:1023px) {
                        margin-left: 25px;
                    }

                    margin-left: 50px;

                    svg {
                        position: absolute;
                        z-index: 0;
                        left: -35px;
                        top: -35px;
                    }

                    a {
                        background-color: $white-color;
                        border-radius: 0;
                        color: $brand-black-color;
                        position: relative;
                        width: 100%;

                        &.active {
                            color: $brand-color;
                            background-color: $grey-background-color;

                            i, span {
                                color: $brand-color;
                            }
                        }

                        &:hover {
                            color: $brand-color;
                        }

                        &:focus {
                            color: $brand-color;
                        }

                        i, span {
                            padding: 10px;
                            color: $brand-black-color;
                            text-align: center;
                            height: 30px;
                            width: 40px;

                            &.active {
                                color: $brand-color;
                            }

                            &:hover {
                                color: $brand-color;
                            }

                            &:focus {
                                color: $brand-color;
                            }
                        }
                    }
                }
            }

            .tab-content_coustom {

                .tab-content {
                    padding: 35px 30px;
                    padding-right: 30px;
                    background-color: #F2F2F2;
                    margin-right: 15px;
                    min-height: 475px;

                    .tab-pane {
                        padding: 0 0 0 15px;
                    }

                    ul {
                        list-style: square;
                        padding: 15px;
                        color: $brand-color;

                        li {
                            color: $black-color;
                        }
                    }

                    a {
                        width: auto;
                    }
                }
            }
        }
    }
}

@media (min-width:0px) and (max-width:767px) {
    .vartical_tab, .tertiary-nav {
        padding-top: 20px;
        padding-bottom: 20px;

        .filled_color_blue {
            display: none;
        }

        .vartical_tab_outer {
            .tabpanel_coustom {
                > div {
                    display: flex;
                }

                .tabpanel_header {
                    padding: 10px;
                }

                .nav-pills {
                    svg {
                        display: none;
                    }

                    a {
                        background-color: $white-color;
                        border-radius: 0;
                        color: $brand-black-color;
                        flex: 1 0 auto;
                        display: block;
                        text-align: center;

                        &:active {
                            color: $brand-color;
                        }

                        &:hover {
                            color: $brand-color;
                        }

                        &:focus {
                            color: $brand-color;
                        }

                        i, span {
                            padding: 10px;
                            color: $brand-black-color;
                            text-align: center;
                            height: 30px;
                            width: 40px;

                            &:active {
                                color: $brand-color;
                            }

                            &:hover {
                                color: $brand-color;
                            }

                            &:focus {
                                color: $brand-color;
                            }
                        }

                        span {
                            display: none;
                            position: absolute;
                            width: 100%;
                            padding: 7px;
                            height: 40px;
                            background: $grey-background-color;
                            margin-top: 5px;
                            left: 0;
                            top: 100%;
                            margin-bottom: 10px;
                            text-align: left;
                        }
                    }

                    .active, .show {
                        background: $grey-background-color;

                        i, span {
                            color: $brand-color;
                        }

                        span {
                            display: block;
                            margin-top: 0;
                            color: $brand-color;
                        }
                    }
                }
            }

            .tab-content_coustom {
                background-color: $grey-background-color;
                margin-top: 15px;

                .tab-content {
                    padding: 20px;

                    ul {
                        list-style: square;
                        padding: 15px;

                        li {
                        }
                    }

                    a {
                        width: 100%;
                    }
                }
            }
        }
    }
}


.flex-fluid-space {
    flex: 1 1 auto;
}
.flex-fluid-item {
    flex: 1 1 1200px;
}
.flex-fluid{
    display: flex;
}
@media (min-width: 768px) {
    .vartical_tab .filled_color_blue {
        height: 100%;
        background-color: #0073B1;
        position: absolute;
        top: 188px;
        max-height: 65px;
        left: -15px;
        right: -250px;
    }
    .tertiary-nav .filled_color_blue {
        height: 100%;
        background-color: #0073B1;
        position: absolute;
        top: 188px;
        max-height: 65px;
        left: -15px;
        right: -250px;
    }
}