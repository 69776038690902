@import "../../../../../../../Z static HTML Prototype/RB/RB.StaticHtml.Website/Assets/Includes/REB/scss/var";

@import "../../../../Project/Website/code/Styles/commonstyle";

.location-detail {
    .location-container {

        .location-banner {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 500px;
            text-transform: uppercase;
            color: $white-color;
            position: relative;

            @include head;

            .banner-overlay {
                width: $width;
                height: $height;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.85;
            }

            .banner-content {
                z-index: 1;

                .banner-wrapper {
                    margin: 0 auto;
                    max-width: 767px;
                    text-align: center;

                    @media(max-width:800px) {
                        width: 90%;
                    }

                    .city-name {
                        margin-bottom: 10px;
                        color: $white-color;
                        font-size: 4rem;

                        @media(max-width:600px) {
                            font-size: 2.5rem;
                        }
                    }

                    .city-address {
                        color: $white-color;
                        font-size: 2rem;

                        @media(max-width:600px) {
                            font-size: 1.2rem;
                        }
                    }
                }
            }
        }

        .banner-layer {
            @include background-layer;

            span {
                display: inline-block;
                vertical-align: middle;
                margin-right: 20px;

                @media(max-width:800px) {
                    margin-bottom: 18px;
                    line-height: 1.5;
                }
            }

            .button-outline {
                background: 0 0;
                border: solid 2px #fff;
                color: $white-color;
                padding: 12px 16px;
                border-radius: 4px;

                &:hover {
                    background: #fff;
                    color: #28aae1;
                    text-decoration: none;
                }

                i {
                    margin-right: 2px;
                }
            }
        }

        .location-list {
            min-height: 583px;
            overflow: hidden;
            overflow-y: scroll;

            .location-list-display {


                .info-block {
                    @include block;
                    border-bottom: solid 1px #f0f0f0;
                    background-color: #fdfdfd;
                    height: 160px;

                    &.back-to-location {
                        height: auto;
                        padding: 0;
                        background: #fbfbfb;

                        a {
                            line-height: 47px;

                            span {
                                margin-right: 8px;
                                text-align: center;
                                background: #f2f2f2;
                                border: none;
                                cursor: pointer;
                                display: block;
                                float: left;
                                font-size: 24px;
                                height: 47px;
                                padding: 14px;
                                position: relative;
                                width: 47px;
                            }
                        }
                    }

                    .info-icon {
                        @include icon;

                        @media(max-width:1024px) {
                            display: none;
                        }
                    }

                    .info-info {
                        @include info;

                        strong {
                            color: #14467a;
                        }

                        @media(max-width:768px) {
                            width: $width;
                            text-align: center;
                            display: block;
                        }

                        @media screen and (max-width:1024px) and (min-width:769px) {
                            width: $width;
                            text-align: left;
                        }
                    }

                    .info-more {
                        @include more;
                        position: relative;
                        height: 100%;

                        @media(max-width:768px) {
                            text-align: center;
                        }

                        @media screen and (max-width:1024px) and (min-width:769px) {
                            width: 100%;
                            text-align: left;
                            padding: 0 10px;
                        }



                        .extra-link {
                            @media(max-width:800px) {
                                text-align: center;
                                width: $width;
                            }

                            @media screen and (max-width:1024px) and (min-width:769px) {
                                width: $width;
                                text-align: left;
                                bottom: auto;
                                right: auto;
                            }
                        }
                    }
                }

                .info-types {

                    @extend .info-block;
                    border-bottom: 0;
                    height: auto;

                    .vert-center {
                        text-align: center;
                        padding: 0;

                        li {
                            display: inline-block;
                            font-size: .7rem;
                            color: #888;
                            margin: 0 30px;

                            i {
                                font-size: 1rem;
                                margin-right: 6px
                            }
                        }
                    }
                }
            }
        }

        .city-maps {
            @include map(583px);
        }
    }
}

.edge {
    padding-left: 0;
    padding-right: 0;
}
