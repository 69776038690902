#loan-type-check-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: rgba(0, 37, 61, 0.98);
    display: none;

    .loan-type-question {
        background: $white;
        width: 90%;
        max-width: 650px;
        margin: 0 auto;
        padding: 40px;
        box-sizing: border-box;
        text-align: center;

        .close-type-modal {
            position: absolute;
            right: 0;
            top: -22px;
            font-size: .7rem;
            cursor: pointer;
            color: $white;
        }

        .init-question {
            h4 {
                margin-bottom: 20px;
            }

            .button {
                display: inline-block;
            }
        }

        h4 {
            margin: 0;
            font-family: "Lato",sans-serif;
            font-size: 1.3rem;
            text-transform: initial;
            font-weight: 900;
            color: $black;

            @media (max-width: 1250px) {
                font-size: 1.2rem;
            }

            @media (max-width: 1024px) {
                font-size: 1.1rem;
            }

            @media (max-width: 768px) {
                font-size: 1rem;
            }

            @media (max-width: 520px) {
                font-size: 0.9rem;
            }
        }

        .please-call {
            display: none;
            margin-bottom: 0px;
        }
    }
}
