.servicing-home {

    #servicing-faq {
        padding: 80px 0 100px;
        background: $white;

        @media (max-width: 1024px) {
            padding: 55px 25px;
        }

        @media (max-width: 520px) {
            padding: 45px 25px;
        }

        .wrapper {
            max-width: 1175px;

            @media (max-width: 1024px) {
                width: 100%;
            }
        }

        .button {
            display: inline-block;
            margin: 0;
            min-width: 183px;

            &.no-faq {
                margin-left: 10px;

                @media (max-width: 767px) {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }

        h2 {
            text-align: center;
            margin-bottom: 15px;
            color: $home-blue;
            text-transform: initial;
            font-family: "Lato",sans-serif;
            font-weight: 900;
        }

        p {
            text-align: center;
            margin-bottom: 60px;
            font-weight: 700;
            font-size: 1.1rem;
            color: $black;

            @media (max-width: 1250px) {
                margin-bottom: 50px;
            }

            @media (max-width: 520px) {
                margin-bottom: 35px;
            }

            @media (max-width: 1250px) {
                font-size: 1rem;
            }

            @media (max-width: 520px) {
                font-size: 0.9rem;
            }
        }

        .servicing-faq-popup {
            border: solid 1px $gray;
            background: $color-snow;
            padding: 50px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            max-width: 700px;
            margin: 0 auto;

            @media (max-width: 1024px) {
                padding: 40px;
            }

            @media (max-width: 520px) {
                padding: 30px;
            }
        }

        .confirmation-popup-close-btn {
            display: none;
            height: 12px;
            width: 12px;
            position: absolute;
            right: 10px;
            top: -20px;
            cursor: pointer;
        }

        .faq-type {
            display: none;

            &.open {
                position: relative;
                display: block;

                .confirmation-popup-close-btn {
                    display: inline-block;
                }
            }

            p {
                font-weight: bold;
                margin-bottom: 20px;
            }

            h4 {
                /*margin: 0;*/
                font-family: "Lato",sans-serif;
                /*font-size: 1.3rem;
                margin-bottom: 36px;*/
                text-transform: initial;
                font-weight: bold;
                font-size: 1.3rem;
                line-height: 1.2em;
                color: $black;
                font-weight: 900;

                @media (max-width: 1250px) {
                    font-size: 1.2rem;
                }

                @media (max-width: 1024px) {
                    font-size: 1.1rem;
                }

                @media (max-width: 768px) {
                    font-size: 1rem;
                }

                @media (max-width: 520px) {
                    font-size: 0.9rem;
                }
            }

            .please-call {
                display: none;
            }
        }

        #faq-wrapper {
            display: none;
            border: solid 1px $gray;
            max-width: none;
            text-align: left;
            padding: 0;

            &.open {
                position: relative;
                display: block;

                .confirmation-popup-close-btn {
                    display: inline-block;
                }

                &.hide-close-btn {
                    .confirmation-popup-close-btn {
                        display: none;
                    }
                }
            }

            .single-faq.active:before {
                color: $white;
                content: '\f056';
                font-family: "FontAwesome";
            }

            .single-faq.active {
                background: #eaf9ff;

                .single-question {
                    background: $home-blue;
                    color: $white;

                    a {
                        color: $color-cerulean;
                    }
                }
            }

            .single-faq {
                line-height: 1.3em;
                background: #f9f9f9;
                border-bottom: solid 1px $gray;
                position: relative;

                .single-question {
                    font-size: 1rem;
                    line-height: 1.4em;
                    padding: 25px 25px 25px 55px;
                    cursor:pointer;
                }

                .single-answer {
                    display: none;
                    padding: 25px 55px 10px 55px;

                    p {
                        text-align: left;
                        font-weight: 400;
                        margin-bottom: 20px;
                        font-size: 1rem;
                    }

                    ul {
                        line-height: 1.5em;
                        margin-bottom: 20px;
                        list-style: disc;
                        margin-left: 40px;
                    }
                }
            }

            .single-faq:before {
                position: absolute;
                top: 29px;
                left: 25px;
                content: '\f055';
                font-family: 'FontAwesome';
                z-index: 1;
                color: $home-blue;
                font-size: 1.1rem;
                line-height: 1em;
            }
        }
    }
}
