.dc-tree {
    font: 16px 'Roboto',Verdana, sans-serif;
    text-align: center;
    background-color: #f2f2f2;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    position: absolute;
    margin: 0 auto;
    background-color: $white;
    height: 95vh;
    max-height: 775px;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    z-index: 9999999;
    width: 90%;
    max-width: 1200px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    background: white;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.dc-tree * {
    box-sizing: border-box;
}

.dctree-card {
    padding: 20px;
    position: absolute;
    margin: 0 auto;
    background-color: $white;
    display: none;
    height: 95vh;
    max-height: 775px;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    z-index: 9999999;
    width: 80%;
    max-width: 1200px;
    left: 100%;
    right: 0;
    margin: 0 auto;
    top: 50%;
    background: white;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    &-input {
        .dctree-item {
            max-width: none;

            &.action-btn {
                text-align: right;
                padding: 0;
            }

            .dctree-answer {
                width: 100%;

                &.dctree-btn {
                    width: auto;
                    display: inline-block;
                }
            }

            &.dctree-input {
                padding-left: 0;
                padding-right: 0;

                .dctree-answer {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

.dctree-message {
    font-size: 1.7rem;
    color: #084875;
    margin-bottom: 20px;
    font-weight: 500;
}

.close-modal {
    text-align: right;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #ccc;
    font-size: 2rem;
    cursor: pointer;
    z-index: 99999999;
    font-family: $opensans;
}

.red {
    background-color: red;
    color: white;
}

.green {
    background-color: green;
    color: white;
}

.orange {
    background-color: orange;
    color: white;
}

.dctree-decisions {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;
}

.dctree-item {
    max-width: 550px;
    margin: 0 auto;

    input {
        width: 100%;
        border: solid 1px #ccc;
        font-size: 1.2rem;
        padding: 8px;
        line-height: 1em;
        min-height: 0;
        border-radius: 0;
    }

    .action-btn {
        text-align: right;
        width: auto;

        .dctree-answer {
            width: auto;
            background-color: red;
            display: inline-block;
        }
    }
}

.dctree-answer {
    &.blue-bg {
        background-color: $skyblue;

        &:hover {
            background-color: $mortgage-blue;
            cursor: pointer;
        }
    }
}


[class^="dctree-answer"] {
    color: $white;
    width: 550px;
    font-size: 1.2rem;
    padding: 10px;
    margin-bottom: 12px;
    font-weight: 500;
}

.dctree-controls {
    position: relative;
    text-align: left;
    margin-top: 35px;
    padding-left: 0;

    a {
        color: #888;
        font-family: lato,sans-serif;
        font-weight: 500;
        font-size: .8rem;
        padding: 11px 0;
        line-height: 1em;
    }
}

.first {
    display: block;
    left: 0;
}

.dctree-prev-btn {
    text-align: left;

    a {
        color: #888;
        font-family: lato,sans-serif;
        font-weight: 500;
        font-size: .8rem;
    }
}

.decision-slider {
    display: none;
}

.decision-slider-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: #00253d;
    z-index: 99999;
    display: none;
}

.restart-tree-btn {
    text-align: right;
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: #ccc;
    font-size: .8rem;
    cursor: pointer;
    z-index: 99999999;
    font-family: "Lato",sans-serif;

    i {
        font-size: 1rem;
        vertical-align: middle;
        margin-right: 4px;
    }

    .restart-tree {
        display: inline-block;
    }
}
