﻿@import "var";

@media (min-width:1024px) {
    h1 {
        font-size: 35px;
    }
}

@media (min-width:0px) and (max-width:1023px) {
    h1 {
        font-size: 30px;
    }
}

//Color theme for heading

#main-content {
    .h-c-light-blue, .h-c-light-blue * {
        color: $brand-color;
    }

    .h-c-darker-black, .h-c-darker-black * {
        color: $brand-black-color;
    }

    .h-c-dark-blue, .h-c-dark-blue * {
        color: $brand-blue-dark;
    }

    .h-c-white, .h-c-white * {
        color: $white-color;
    }
}


/*Color Combination For Hero banner heading text */
.brand-color-heading {
    color: $brand-color;
}

.brand-black-color-heading {
    color: $brand-black-color;
}
