.servicing-home {
    .servicing-options {
        padding: 85px 0;
        background: $teal;

        @media (max-width : 520px) {
            padding: 25px 0 10px 0;
        }
        .wrapper {
            max-width: 1200px;
        }

        .servicing-option {
            display: inline-block;
            width: 30.99%;
            vertical-align: top;
            margin: 0 1%;
            background: $white;
            box-sizing: border-box;
            border: solid 1px $gray;

            @media (max-width : 768px) {
                width: 100%;
                margin-bottom: 20px;
            }

            .option-header {
                padding: 20px 25px;
                background: $home-blue;
                color: $white;
            }

            img {
                display: block;
                width: 50%;
                max-width: 100px;
                margin: 25px auto 0 auto;
            }

            .option-content {
                padding: 20px 25px 30px 25px;
                text-align: center;

                h4 {
                    margin-bottom: 15px;
                    text-transform: initial;
                    color: $black;
                    font-family: "Lato",sans-serif;
                    font-size: 23.4px;
                    font-weight: 900;
                }

                p {
                    margin-bottom: 30px;
                    color: $black;
                    font-family: "Lato",sans-serif;
                }

                a.button,
                .button {
                    display: block;
                    box-sizing: border-box;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
}
