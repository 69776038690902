.private-client {
    padding: 16px;
    background-color: #ebebeb;
    margin-left: auto;
    margin-right: auto;
}

.private-client img {
    margin-bottom: 32px !important;
}

.private-client .name {
    font-size: 24px;
    color: #00263d;
    font-family: archivo narrow;
    font-weight: bold;
    margin-bottom: 0;
}

.private-client .title {
    font-size: 16px;
}

.private-client .contacts {
    width: 100% !important;
    margin-bottom: 28px;
}

.private-client .contacts .telephone {
    width: 100% !important;
    margin-bottom: 8px;
}

.private-client .contacts .telephone .fa-phone:before {
    margin-right: 16px;
}

.private-client .contacts .email {
    width: 100% !important;
    margin-bottom: 0 !important;
}

.private-client .contacts .email .fa-envelope:before {
    margin-right: 16px;
}

.private-client .telephone-cta {
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 8px !important;
}

.private-client .telephone-cta a {
    margin-top: 0 !important;
    width: 100% !important;
}

.private-client .email-cta {
    width: 100% !important;
    margin-top: 0 !important;
    margin-bottom: 8px !important;
}

.private-client .email-cta a {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
}
