.officer-profile {
    max-width: 960px;
    margin: 0 auto;
    padding: 30px 15px;

    @media(max-width: 767px) {
        padding: 30px 15px;
    }

    .officer-img {
        display: block;
        vertical-align: top;
        width: 25%;
        margin-right: 5%;
        float: left;
        display: block;

        @media(max-width: 767px) {
            width: 36%;
            margin-right: 7%;
        }

        img {
            width: 100%;
            display: block;
        }
    }

    .officer-details {
        width: 70%;
        float: left;
        vertical-align: top;
        line-height: 1.3em;
        font-size: .95rem;
        margin: 10px 0 0 0;

        @media(max-width: 767px) {
            width: 57%;
            font-size: .8rem;
        }

        &_inner {
            float: left;
            width: auto;
            display: inline-block;

            div {
                margin: 0 0 5px;

                span {
                    font-weight: 700;
                    color: #00253d;
                }
            }
        }

        h1 {
            font-weight: 700;
            margin: 0 0 10px;
            color: #00253d;
            font-size: 1.8rem;
            font-family: $opensans;
            text-transform: none;

            @media(max-width: 767px) {
                font-size: 1.3rem;
                margin: 0 0 8px;
            }
        }

        .officer-position {
            font-weight: 700;
            font-size: 1rem;
            text-transform: uppercase;
            letter-spacing: 0.025em;
            margin: 0 0 20px;

            @media(max-width: 767px) {
                font-size: .8rem;
            }
        }

        .officer-nmls {
            margin: 0 0 20px;
        }

        .officer-address {
            color: #00253d;
            font-weight: 700;
            margin: 0 0 25px;
        }

        .officer-city-state {
            margin: 0 0 20px;
        }

        .officer-phone {
            margin: 0 0 5px;
        }
    }

    .pre-qual {
        float: right;

        a {
            display: inline-block;
            letter-spacing: .025em;
            margin: 15px 0 0 5px;
            background: #00253d;
            border: solid 2px #00253d;
            box-sizing: border-box;
            color: #fff;
            font-weight: 500;
            font-size: .75rem;
            padding: 12px 16px;
            line-height: .75rem;
            cursor: pointer;
            font-family: lato,sans-serif;
            -webkit-transition: all 150ms linear;
            -o-transition: all 150ms linear;
            transition: all 150ms linear;

            @media(max-width: 767px) {
                margin-left: 0;
            }

            &:hover {
                color: #00253d;
                background: $white;
                border: 2px solid #00253d;
            }
        }

        @media(max-width: 767px) {
            float: none;
            display: inline-block;
            width: 100%;
            text-align: right;
        }
    }

    .officer-bio {
        font-size: 1rem;
        line-height: 1.5em;
        margin: 30px 0 20px;
        word-spacing: 2px;
    }

    .officer-bio-note {
        font-size: 70%;
        color: #888888;
        font-style: italic;
    }

    .schedule-call-link {
        margin-bottom: 25px;
        letter-spacing: 2px;
    }
}
