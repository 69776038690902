
.home-hero {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(86vh - 130px);
    min-height: 565px;
    margin: 0 auto;
    box-sizing: border-box;
    background: $white;

    .col3.mid-col {
        margin: 0 9px;
    }

    .col3 {
        position: relative;
        z-index: 1;
        width: calc(33.333333333333336% - 6px);
        height: 100%;
        min-height: 530px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        overflow: hidden;

        .option-content-wrap {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background: $white;
            background-size: cover;
            background-position: top left;
            background-repeat: no-repeat;
            cursor: pointer;

            &:hover {
                .option-color-overlay {
                    opacity: 1;
                    background: rgba(0,21,42,0.9);
                }

                .option-content {
                    background: rgba(0,37,61,0.75);

                    &.vert-center {
                        background: 0 0;

                        p {
                            color: $white;
                        }

                        .line-top {
                            width: 100%;
                        }

                        .line-right {
                            height: 100%;
                        }

                        .line-bottom {
                            width: 100%;
                        }
                    }

                    .option-icon {
                        .loop-img {
                            width: 200px;
                        }
                    }
                }

                h3 {
                    visibility: hidden;
                }

                .dark-blue-link {
                    background-color: $white;
                    background: $white;
                    border-color: $white;
                    color: $home-blue;
                }
            }

            .option-color-overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: top left;
                background-repeat: no-repeat;
                opacity: 0;
                cursor: pointer;
                transition: all 500ms ease;

                @media(max-width: 768px) {
                    opacity: 1;
                    background: rgba(0,21,42,0.9);
                }
            }

            .option-content.vert-center {
                width: 90%;
                max-width: 500px;
                z-index: 1;
                text-align: left;
                background: rgba(255, 255, 255, 0.9);
                box-sizing: border-box;
                padding: 60px 40px 70px 40px;
                transition: all 500ms ease;

                p {
                    position: relative;
                    top: 0;
                    margin: 0;
                    opacity: 1;
                    height: 120px;
                    color: $dark;

                    @media (max-width: 1550px) {
                        font-size: .95rem;
                    }

                    @media (max-width: 1024px) {
                        font-size: .9rem;
                    }

                    @media (max-width: 960px) {
                        font-size: .85rem;
                    }

                    @media (max-width: 768px) {
                        height: auto;
                        margin-bottom: 30px;
                        color: $white;
                    }
                }

                .option-icon {
                    position: relative;
                    width: 100%;
                    height: 55px;
                    opacity: 1;
                    padding: 0 0 110px;

                    @media (max-width: 768px) {
                        padding: 0 0 60px;
                    }

                    img.loop-img {
                        z-index: 3;
                        opacity: 0;
                    }

                    img.intro-img {
                        z-index: 2;
                        opacity: 0;
                    }

                    img.icon-img,
                    img.intro-img,
                    img.mobile-img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        z-index: 1;
                        opacity: 0;
                        width: 200px;
                    }

                    img.reveal {
                        opacity: 1;
                    }

                    img.mobile-img {
                        @media (max-width: 1024px) {
                            width: 150px;
                        }

                        @media (max-width: 768px) {
                            opacity: 1 !important;
                        }
                    }

                    img.intro-img,
                    img.icon-img,
                    img.loop-img {
                        @media (max-width: 768px) {
                            display: none;
                        }
                    }
                }

                h3 {
                    position: relative;
                    top: 0;
                    margin-bottom: 20px;
                    color: $home-blue;
                    /*font-size: 1.7rem;*/
                    line-height: 1.4em;
                    opacity: 1;
                    text-transform: none;
                    font-weight: 900;
                    font-family: 'Lato';
                    font-size: 1.45rem;

                    @media (max-width: 1250px) {
                        font-size: 1.2rem;
                    }

                    @media (max-width: 1024px) {
                        font-size: 1.1rem;
                    }

                    @media (max-width: 960px) {
                        font-size: .98rem;
                        margin-bottom: 15px;
                    }

                    @media (max-width: 768px) {
                        font-size: 1.2rem;
                        color: $white;
                        opacity: 1 !important;
                    }

                    @media (max-width: 520px) {
                        font-size: 1rem;
                        margin-bottom: 10px;
                    }
                }

                button,
                .button {
                    position: absolute;
                    z-index: 1;
                    bottom: -23px;
                    left: 40px;
                    text-transform: initial;

                    @media(max-width : 768px) {
                        position: relative;
                        bottom: auto;
                        left: auto;
                        font-size: .85rem;
                        background: $white;
                        border-color: $white;
                        color: $home-blue;
                    }
                }

                i {
                    margin-left: 5px;
                }

                .line-top,
                .line-right,
                .line-bottom {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background: $white;
                    -webkit-transition: all 500ms ease;
                    -o-transition: all 500ms ease;
                    transition: all 500ms ease;
                }

                .line-right {
                    width: 2px;
                    right: 0;
                    left: auto;
                }

                .line-bottom {
                    right: 0;
                    left: auto;
                    bottom: 0;
                    top: auto;
                }

                .dark-blue-link {
                    &:hover {
                        background-color: $white;
                        background: $white;
                        border-color: $white;
                        color: $home-blue;

                        i {
                            left: 0;
                        }
                    }
                }

                @media (max-width: 1250px) {
                    padding: 50px 30px 80px;
                    width: 95%;
                }

                @media (max-width: 1024px) {
                    width: 98%;
                    padding: 40px 25px 80px;
                }

                @media (max-width: 768px) {
                    width: 100%;
                    padding: 45px 30px;
                    background: 0 0;
                    top: auto;
                    transform: none;
                }
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            float: none;
            min-height: 0;
            height: auto;
            margin: 6px 0;
        }

        &:first-child {
            @media (max-width: 768px) {
                margin-top: 0;
            }
        }

        &:last-child {
            @media (max-width: 768px) {
                margin-bottom: 0;
            }
        }
    }

    .home-hero-item {
        &:nth-child(2) {
            margin: 0 9px;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        float: none;
        min-height: 0;
        height: auto;
    }

    .vert-center {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.col3 {
    float: left;
    width: 33.3333%;
}
