@import "../base/var";

.hero_banner {
    &_container {
        .hero_banner_text_area {
            @media (min-width: 1024px) {
                max-width: 45%;
                left: -20px;
            }
        }
    }

    &.large {
        &.left-vertical {
            .hero_banner_container {
                .container-content {
                    position: unset;
                }

                .hero_banner_image {
                    height: 85vh;
                    max-height: 750px;

                    @media (min-width: 1024px) {
                        &.desktop {
                            display: block;
                        }

                        &.mobile {
                            display: none;
                        }
                    }

                    @media (min-width: 0px) and (max-width:1023px) {
                        &.desktop {
                            display: none;
                        }

                        &.mobile {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &.large {
        .hero_banner_container {
            .container-content {
                position: unset;
            }

            .hero_banner_image {
                height: 600px;
                max-height: 750px;
            }
        }
    }

    &.left-center {
        .hero_banner_text_area {
            top: 50%;
            transform: translateY(-50%);
            max-width: 540px;
            left: 9.5%;
        }
    }

    &.left-bottom {
        .hero_banner_text_area {
            top: auto;
            transform: none;
            bottom: 0;
            max-width: 540px;
            left: 9.5%;
        }
    }

    &.left-vertical {
        font-size: 1.1rem;
        overflow: hidden;

        .hero_banner_container {
            .container-content {
                .hero_banner_text_area {
                    top: 50%;
                    transform: translateY(-50%);
                    bottom: auto;
                    width: 460px;
                    left: 7vw;
                    background-color: transparent;
                    max-width: none;
                    padding: 0;
                    font-family: $opensans;

                    @media(max-width: 960px) {
                        background: rgba(243,243,243,0.9);
                        padding: 25px 25px 30px 25px;
                        width: 440px;
                    }
                }
            }

            h1 {
                font-size: 2.7rem;
                font-family: $opensans;
                color: #14467a;

                @media (min-width: 1024px) {
                    font-size: 38px;
                }
            }
        }
    }

    #hero-slant {
        background: #f3f3f3;
        position: absolute;
        top: -65%;
        right: 50%;
        width: 100%;
        height: 200%;
        z-index: 0;
        opacity: 0.9;
        transition: all 300ms linear;
        transform: rotate( 15deg );

        @media(max-width: 960px) {
            display: none;
        }
    }

    .hero_banner_container {
        position: relative;

        .hero_banner_image {
            background-image: url("./assets/images/heroslider-demo.png");
            background-color: #15467B;
            background-size: cover;
            background-position: center center;
            min-height: 500px;

            @media (min-width: 1024px) {
                &.desktop {
                    display: block;
                }

                &.mobile {
                    display: none;
                }
            }

            @media (min-width: 0px) and (max-width:1023px) {
                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }
        }

        .container-content {
            position: relative;

            .hero_banner_text_area {
                position: absolute;
                bottom: -1px;
                padding: 25px 60px 15px 35px;

                @media (max-width: 1023px) {
                    left: 0;
                }

                width: auto;
                min-width: 21%;
                background-color: $white-color;
                padding-bottom: 0;

                h2 {
                    padding-bottom: 0;
                    padding-top: 0;

                    @media( width:1024px) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    @include archivo-bold(35px);

                    @media (max-width: 1023px) {
                        @include archivo-bold(30px);
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 1.4em;
                }
            }
        }
    }

    .mobile_hero_bannner_text {
        display: none;
    }
}

//Css for mobile view 0px t0 1023px device width
@media (max-width:1023px) {


    .hero_banner {
        .hero_banner_container {
            position: relative;

            .hero_banner_image {
                background-image: url("./assets/images/heroslider-demo.png");
                background-color: #15467B;
                background-size: cover;
                background-position: center center;
                min-height: 300px;
            }

            .hero_banner_text_area {
                position: absolute;
                bottom: -1px;
                left: 0;
                width: auto;
                max-width: 60%;
                padding: 15px;
                padding-top: 0;
                padding-bottom: 0;
                height: auto;
                background-color: $white-color;

                @media(max-width:767px) {
                    width: auto;
                    padding-right: 35px;
                    max-width: 80%;
                }

                h2 {
                    margin: 10px 0;
                    @include archivo-bold(30px);

                    @media(width:768px) {
                        padding-left: 10px;
                    }
                }

                p {
                    display: none;
                }
            }
        }

        .mobile_hero_bannner_text {
            display: block;
            padding: 15px;

            @media(max-width:767px) {
                padding: 15px;
            }
        }
    }
}
