.hero-dark-overlay {
    a {
        display: block;
    }

    &__inner {
        min-height: 625px;
        position: relative;

        @media (max-width: 768px) {
            min-height: auto;
            padding: 46vw 0 0;
        }
    }

    &__content {
        width: 85%;
        max-width: 700px;
        position: absolute;
        bottom: 40px;
        left: 45px;
        color: white;
        background: rgba(0,0,0,0.75);
        padding: 30px 30px 40px;

        @media (max-width: 768px) {
            position: inherit;
            bottom: auto;
            left: auto;
            width: 100%;
            padding: 25px;
            background: $blue;
        }

        h1 {
            color: $light-blue;
            margin-bottom: 15px;

            @media (max-width: 767px) {
                font-size: 1.5rem;
            }
        }

        p {
            color: $white;

            @media (max-width: 767px) {
                display: none;
            }
        }
    }
}
