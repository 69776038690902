.loan-officer-search {
    width: 100%;
    height: 55vw;
    max-height: 750px;
    min-height: 85vh;
    background-size: cover;
    text-align: center;
    color: $white;

    &__inner {
        position: relative;
        top: 45%;
        transform: translateY(-50%);
    }

    &__content {
        @media (max-width: 650px) {
            padding: 0 20px;
        }
    }

    &__title {
        font-weight: 700;
        margin: 0 0 20px;
        color: $white;
        font-size: 2.8rem;
        font-family: $opensans;
        line-height: 1.2em;
        text-transform: none;

        @media (max-width: 1024px) {
            font-size: 2rem;
            margin: 0 0 15px;
        }

        @media (max-width: 768px) {
            font-size: 1.75rem;
        }

        @media (max-width: 650px) {
            font-size: 1.8rem;
        }
    }

    &__sub-title {
        font-size: 1.4rem;
        margin: 0 0 40px;
        color: $white;
        line-height: 1.5em;

        @media (max-width: 1024px) {
            font-size: 1.2rem;
        }

        @media (max-width: 768px) {
            font-size: 1rem;
        }

        @media (max-width: 650px) {
            font-size: .8rem;
        }
    }

    .officer-disclaimer {
        margin: 30px 0 0 0;
        font-size: .8rem;
        color: $white;

        @media (max-width: 650px) {
            font-size: .65rem;
        }
    }

    select {
        width: 100%;
        max-width: 750px;
        border: none;
        background: $white;
        font-size: 1.3rem;
        font-weight: 500;
        font-family: $opensans;
        line-height: normal;
        padding: 12px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        -webkit-appearance: none !important;

        @media (max-width: 768px) {
            font-size: .7rem;
        }

        @media (max-width: 1024px) {
            font-size: .9rem;
        }

        option {
            &:hover {
                cursor: pointer;
            }
        }

        @media (max-width: 650px) {
            height: 40px;
            padding: 8px 10px;
            font-size: 1rem;
        }
    }
}
