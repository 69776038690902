
.Spirit_Card {
    .bg-light-blue {
        .search-form {
            h3 {
                color: white;
            }

            input {
                width: 36%!important;
                border: 2px solid white;
                color: white;
                padding-left: 15px;
                background-color: #0073B1;
            }
        }
    }
}
