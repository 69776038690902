﻿@import "../base/var";

.header {
    position: relative;
    background: linear-gradient(to right, white 80%, $grey-background-color 20%);
    box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.1);
    z-index: 3;

    nav {
        z-index: 999;
        padding: 0;
        position: static;

        .iconbar {
            color: $brand-color;
            font-size: 35px;
        }

        .nav-right-menu {
            order: 3;
        }

        .navbar-collapse {
            background: $white-color;
            order: 2;

            ul {
                li {

                    .nav-link {
                        /*&:hover {
                            .right-side-down-arrow {
                                transform: rotate(-180deg);
                            }
                        }*/
                        .right-side-down-arrow {
                        }

                        .right-side-up-arrow {
                            display: none;
                        }
                    }
                    /*&.active {
                        .right-side-down-arrow {
                            transform: rotate(-180deg);
                        }
                    }*/
                }
            }

            .dropdown-menu {
                margin-top: 0;

                @media(min-width:1024px) {
                    &.mobile-only {
                        display: none;
                    }
                }

                .row {
                    padding-top: 40px;
                    padding-bottom: 0;
                    width: 80%;
                    margin: 0 auto;
                }

                ul {
                    li {
                        h6 {
                            color: $brand-light-color;
                            @include muli-bold(15px);
                        }

                        a {
                            @include muli-regular(14px);
                            color: $brand-black-color;                            
                        }
                    }
                }

                .menu-bottom-links {
                    background-color: $grey-background-color;
                    padding: 10px 30px 10px 0px;
                    text-align: center;

                    ul {


                        li {
                            border: none;
                            background: transparent;
                            padding: 0.75rem 2%;
                            flex: 1 1 auto;

                            @media(max-width:1023px) {
                                max-width: none;
                            }

                            a {
                                color: $brand-dark-color;
                                width: 100px;
                                text-align: center;

                                .mega-menu-item-icon {
                                    font-size: 30px;
                                }

                                span {
                                    display: block;
                                }

                                &:hover {
                                    color: $brand-ligher-color;
                                    text-decoration: none;
                                }
                            }

                            &:first-child {
                                padding-left: 0;
                            }

                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }

                &.active {
                    display: block;
                    opacity: 1;
                    transform: none;
                    transition: 0.3s linear all;
                    visibility: visible;
                    padding-top: 0;

                    &.show {
                        display: none;
                    }
                }

                .nav-item {
                    .nav-link {
                        color: #1c1c1c;
                        padding: 6px 0;
                    }
                }

                .social-media-links {
                    padding: 30px 50px;

                    a {
                        margin-left: 30px;
                        font-size: 17px;

                        &.first {
                            margin-left: 50px;
                        }
                    }
                }
            }
        }

        .dropdown-toggle:after {
            content: "\f078";
            font-family: FontAwesome;
            border: none;
            vertical-align: 0.18em;
            font-size: 11px;
            display: none;
        }

        .dropdown-item {
            &:focus, &:hover {
                background-color: #265196;
                color: $white-color;
            }
        }

        .navbar-brand {
            color: $white-color;
            order: 1;
            display: inline-block;

            img {
                max-width: 180px;
            }
        }

        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $brand-black-color;
                    padding: 18px 15px;
                    position: relative;
                    top: 17px;
                }

                &.active, &.show {
                    .nav-link {
                        color: $brand-color;

                        .right-side-down-arrow {
                            transform: rotate(-180deg);
                        }
                    }

                    .dropdown-menu, & + .dropdown-menu {
                        opacity: 1;
                        transform: none;
                        transition: 0.3s linear all;
                        visibility: visible;
                    }
                }
                /*.show {
                    opacity: 1;
                    transform: none;
                    transition: 0.3s linear all;
                    visibility: visible;
                }*/
            }
        }

        .nav-right-menu {
            .top-menu {
                height: 34px;
                text-align: right;
                background-color: #F2F2F2;
                padding-right: 5px;

                a {
                    @include muli-regular(10px);
                    color: $grey-text-color;
                    margin-right: 20px;


                    &:last-child {
                        margin-right: 0;
                    }

                    i, span {
                        color: $dark-grey-color;
                        font-size: 14px;
                        margin-right: 6px;
                    }
                }
            }

            .button-div {
                display: flex;

                .btn {
                    width: 50%;
                    float: left;
                    margin: 0;
                    min-width: 160px;
                }
            }
        }
    }
}



@media (max-width:1023px) {
    .header {
        background: $white-color;

        nav {
            z-index: 999;
            padding: 0;

            button {
                float: right;
                padding: 10px;
                padding-top: 15px;
                padding-right: 20px;

                .iconbar {
                    color: $brand-dark-color;
                    font-size: 35px;
                }
            }

            .navbar-collapse {
                max-width: 50%;
                margin-left: 15rem;

                ul {
                    flex-direction: column;
                    flex: 1;
                    margin: 0;
                    padding: 5px 0;

                    li {
                        order: -1;
                    }
                }

                .dropdown-menu {
                    margin-top: 0px;

                    &.desktop-only {
                        display: none;
                    }

                    ul {
                        li {
                            h6 {
                                color: $brand-light-color;
                                @include muli-bold(12px);
                            }

                            a {
                                @include muli-regular(14px);
                                color: $brand-black-color;
                            }
                        }
                    }

                    .menu-bottom-links {
                        background-color: $grey-background-color;
                        padding: 30px;
                        text-align: center;

                        ul {



                            li {
                                border: none;
                                background: transparent;
                                padding: 0.75rem 1.75rem;

                                a {
                                    color: $brand-dark-color;
                                    width: 100px;
                                    text-align: center;

                                    .list-item-icon {
                                        font-size: 37px;
                                    }

                                    span {
                                        display: block;
                                        font-size: 17px;
                                    }

                                    &:hover {
                                        color: $brand-ligher-color;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }

                    &.active {
                        display: block;

                        &.show {
                            display: none;
                        }
                    }

                    .nav-item {
                        .nav-link {
                            color: #1c1c1c;
                            padding: 6px 0;
                        }
                    }

                    .social-media-links {
                        padding: 50px;

                        a {
                            margin-left: 30px;
                            font-size: 21px;
                            color: $brand-light-color;

                            &.first {
                                margin-left: 50px;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    background-color: $grey-background-color;
                    border: none;
                    border-radius: 0;
                    padding-top: 0;

                    .container {
                        display: none;
                    }

                    .container-fluid {
                        padding-right: 0;
                        margin: 0 !important;
                        padding-left: 0;

                        .menu-bottom-links {
                            width: 100%;
                            padding: 0;

                            ul {
                                width: 100%;
                                margin-top: 0;
                                display: inline-block;
                                padding: 5px;
                                min-width: 100%;

                                li {
                                    background-color: $white-color;
                                    margin: 5px;
                                    padding: 36px 11px 11px 0;
                                    float: left;

                                    @media (max-width:767px) {
                                        width: 47%;
                                    }

                                    @media (min-width: 768px) and (max-width:1023px) {
                                        width: 23.5%;
                                    }
                                }
                            }
                        }

                        .social-media-links {
                            width: 100%;
                            background-color: $white-color;
                            padding: 13px;
                            text-align: center;
                        }
                    }
                }
            }

            .dropdown-toggle:after {
                content: "\f078";
                font-family: FontAwesome;
                border: none;
                vertical-align: 0.18em;
                font-size: 11px;
            }

            .dropdown-item {
                &:focus, &:hover {
                    background-color: #265196;
                    color: $white-color;
                }
            }

            .navbar-brand {
                color: $white-color;
                padding: 16px;

                img {
                    max-width: 180px;
                }
            }

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: $brand-color;
                        padding: 34px 15px;
                        top: 0;

                        &:hover {
                            color: $brand-color;
                        }
                    }

                    &:hover {
                        .dropdown-menu {
                            opacity: 1;
                            transform: none;
                            transition: 0.3s linear all;
                            visibility: visible;
                        }
                    }

                    &.show + .dropdown-menu {
                        display: block;
                    }
                }

                .dropdown-menu {
                    .col-lg-7, .col-lg-5 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }

            .nav-right-menu {
                background-color: #F2F2F2;

                .top-menu {
                    height: 30px;
                    padding-left: 150px;

                    a {
                        @include muli-regular(10px);
                        color: $brand-black-color;
                        margin-right: 20px;
                    }
                }

                .button-div {
                    display: block;

                    .btn {
                        padding: 1em 4em 1em 4em;
                    }
                }
            }
        }
    }

    .navbar-expand-lg {
        .navbar-toggler {
            display: inline-block;
            padding: 28px;
            outline: none;

            span {
                height: 20px;
                width: 30px;
                display: block;

                span {
                    width: 100%;
                    height: 3px;
                    margin: 3px 0;
                    background-color: $brand-blue-dark;
                }
            }
        }

        .navbar-collapse {
            &.collapse:not(.show) {
                display: none !important;
            }
        }
    }

    .right-side-down-arrow, .right-side-up-arrow {
        display: none;
    }
}



@media (min-width: 769px) and (max-width: 1023px) {

    .header {
        nav {
            z-index: 999;
            padding: 0;

            .iconbar {
                color: $brand-color;
                font-size: 35px;
            }

            .navbar-collapse {
                max-width: 50%;
                margin-left: 2%;
            }

            .dropdown-menu {
                margin-top: 0;

                .row {
                    margin-top: 50px;
                }

                ul {
                    li {
                        h6 {
                            color: $brand-light-color;
                            @include muli-bold(12px);
                        }

                        a {
                            @include muli-regular(14px);
                            color: $brand-black-color;
                        }
                    }
                }

                .menu-bottom-links {
                    background-color: $grey-background-color;
                    padding: 30px;
                    text-align: center;

                    ul {



                        li {
                            border: none;
                            background: transparent;
                            padding: 0.75rem 0.75rem;

                            a {
                                color: $brand-dark-color;
                                width: 100px;
                                text-align: center;

                                i, span {
                                    display: block;
                                }

                                .list-item-icon {
                                    font-size: 30px;
                                    display: inline-block;
                                }

                                &:hover {
                                    color: $brand-ligher-color;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                &.active {
                    display: block;

                    &.show {
                        display: none;
                    }
                }

                .nav-item {
                    .nav-link {
                        color: #1c1c1c;
                        padding: 6px 0;
                    }
                }

                .social-media-links {
                    padding: 50px;

                    a {
                        margin-left: 30px;

                        &.first {
                            margin-left: 50px;
                        }
                    }
                }
            }

            .dropdown-toggle:after {
                content: "\f078";
                font-family: FontAwesome;
                border: none;
                vertical-align: 0.18em;
                font-size: 11px;
            }

            .dropdown-item {
                &:focus, &:hover {
                    background-color: #265196;
                    color: $white-color;
                }
            }

            .navbar-brand {
                color: $white-color;

                img {
                    width: 180px;
                }
            }

            .navbar-nav {
                .nav-item {
                    .nav-link {
                        color: $brand-color;
                        padding: 34px 15px;

                        &:hover {
                            color: $brand-color;
                        }
                    }
                }
            }

            .nav-right-menu {


                .top-menu {
                    height: 34px;
                    padding-left: 40px;
                    background-color: #F2F2F2;

                    a {
                        @include muli-regular(10px);
                        color: $brand-black-color;
                        margin-right: 20px;
                    }
                }

                .button-div {
                    .btn {
                        padding: 1em 2.3em 1em 2.3em;
                    }
                }
            }
        }
    }
}

@media (min-width:1024px) {
    header {
        > .container-fluid {
            position: relative;

            &:before {
                content: '';
                z-index: -1;
                position: absolute;
                background: $white-color;
                height: 100%;
                width: 50%;
                left: 0;
                top: 0;
                right: auto;
            }

            &:after {
                content: '';
                position: absolute;
                background: #f2f2f2;
                height: 100%;
                width: 50%;
                left: auto;
                top: 0;
                right: 0;
                z-index: -1;
            }

            .container-content {


                nav {
                    background: $white-color;

                    .navbar-collapse {
                        ul {
                            margin-right: 5%;
                        }
                    }
                }

                .social-media-links {
                    display: flex;
                    align-items: center;
                    background-color: $white-color;
                }
            }
        }

        .mega-menu-bottom {
            position: relative;
            margin-top: 40px;

            &:before {
                content: '';
                z-index: 0;
                position: absolute;
                background: $grey-background-color;
                height: 100%;
                width: 70%;
                left: 0;
                top: 0;
                right: auto;
            }

            &:after {
                content: '';
                position: absolute;
                background: $white-color;
                height: 100%;
                width: 30%;
                left: auto;
                top: 0;
                right: 0;
                z-index: -1;
            }
        }
    }
}


@media (min-width:1024px) {
    .mobilenavtab {
        display: none;
    }
}

@media (max-width:1023px) {
    .mobilenavtab {
        display: block;
    }
}
