@import "../base/var";

@media (min-width: 1024px){
.column_callout_6_4{
    min-height: 300px;
    padding: 0;
    position: relative;
    .column_callout_6_4_left{
        float: left;
        min-height: 300px;
        padding: 0;
        .column_callout_6_4_left_inner{
            margin-left: auto;
            padding: 25px;
            h2{
                color: $brand-blue-dark;
            }
            p{
                color: $brand-black-color;
            }

        }
    }
    .column_callout_6_4_right{
        background-color: gray;
        min-height: 300px;
        padding: 0;
        float: left;
        .column_callout_6_4_right_inner{
            margin-right: auto;
            padding: 25px;
        }
    }

}
}

@media (min-width :0px) and (max-width: 1023px){
    .column_callout_6_4{
        min-height: 600px;
        padding: 0;
        position: relative;
        .column_callout_6_4_left{
            float: left;
            padding: 0;
            .column_callout_6_4_left_inner{
                margin-left: auto;
                padding: 15px;
                h2{
                    color: $brand-blue-dark;
                }
                p{
                    color: $brand-black-color;
                }
            }
        }
        .column_callout_6_4_right{
            background-color: gray;
            padding: 0;
            float: left;
            .column_callout_6_4_right_inner{
                margin-right: auto;
                padding: 15px;
            }
        }
    
    }
}