@import "../base/var";

@media (min-width:1024px){
    .history_slider {
        padding-top: 50px;
        padding-bottom: 20px;
        background: $grey-background-color;

        .owl-carousel {
            margin: 0 auto;
            text-align: center;

            .owl-stage {
                display: flex;
                text-align: left;

                .owl-item {
                    display: flex;
                    flex: 1 0 auto;
                    border: 1px solid $brand-blue-dark;
                    background: $white-color;

                    .item {
                        width: 100%;

                        .slide_content_outer {
                            display: flex;
                            height: 100%;

                            .history_slider_img {
                                padding: 15px;
                                float: left;
                                display: flex;

                                img {
                                    min-height: 300px;
                                }
                            }

                            .history_slider_content {
                                padding: 15px;
                                float: left;
                                margin-bottom: 100px;

                                a {
                                    display: -webkit-inline-box;
                                    margin-bottom: 10px;
                                    padding: 10px;
                                    background: $grey-background-color;
                                    border: none;
                                    color: $brand-blue-dark;

                                    &:last-child {
                                        display: none;
                                    }
                                }

                                h5 {
                                    padding-top: 15px;
                                    color: $dark-grey-color;
                                    padding-bottom: 10px;
                                     @include muli-regular(13px)
                                    &:first-child {
                                        padding-top: 0;
                                    }
                                }

                                p {
                                    font-size: 16px;
                                    color: $text-blue-color;
                                }
                            }

                            a {
                                display: none;
                            }
                        }
                    }
                }
            }

            .owl-nav {
                margin-top: 50px;

                .owl-prev {
                    height: 50px;
                    width: 50px;
                    background-color: $brand-blue-dark;
                    position: absolute;
                    bottom: 100px;
                    left: 51%;
                    transform: translate(0%,0%);
                    color: $white-color;
                    opacity: 1;

                    &:active {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    &:hover {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    span {
                        font-size: 44px;
                        line-height: 0.6;
                    }
                }

                .owl-next {
                    height: 50px;
                    width: 50px;
                    background-color: $brand-blue-dark;
                    position: absolute;
                    bottom: 100px;
                    right: 40%;

                    @media (min-width:1024px) and (max-width:1389px) {
                        right: 37%;
                    }

                    transform: translate(0%,0%);
                    color: $white-color;
                    opacity: 1;

                    &:active {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    &:hover {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    span {
                        font-size: 44px;
                        line-height: 0.6;
                    }
                }
            }

            .owl-dots {
                margin-top: 50px;
                margin: 0 auto;
                border: 1px solid $brand-blue-dark;
                outline: none;
                min-width: 200px;
                display: inline-block;
                background-color: $white-color;

                .owl-dot {
                    top: -2px;
                    margin: 0 5px;

                    div {
                        display: none;
                    }

                    span {
                        border-radius: 0;
                        background-color: $grey-background-color;
                        border: 1px solid $brand-blue-dark;
                        margin: 0;
                        height: 16px;
                        width: 16px;
                        outline: none;

                        &:before, &:after {
                            content: "";
                            height: 2px;
                            width: 10px;
                            background: $brand-blue-dark;
                            position: absolute;
                            top: 50%;
                            z-index: 0;
                        }

                        &:active {
                            background: $brand-blue-dark;
                            outline: none;
                        }

                        &:before {
                            left: -10px;
                        }

                        &:after {
                            left: auto;
                            right: -10px;
                        }
                    }

                    &:first-child {
                        span {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    &:last-child {
                        span {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }

                .active {
                    position: relative;
                    margin: 4px 14px 0;
                    top: 1px;

                    span {
                        background: $brand-blue-dark;
                        height: 22px;
                        width: 22px;
                        outline: none;
                        margin: 0;
                    }

                    div {
                        display: block;
                        position: absolute;
                        top: -50px;
                        border: 1px solid $brand-blue-dark;
                        padding: 0 10px;
                        left: 50%;
                        margin-left: -28px;
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        @include archivo-bold(22px);

                        &::after {
                            content: "";
                            height: 20px;
                            width: 2px;
                            background: $brand-blue-dark;
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            margin-left: -1px;
                        }
                    }


                    .histor_date {
                        display: block;
                        background: white;
                        width: auto;
                        height: auto;
                        position: absolute;
                        top: -170%;
                        left: -20%;
                        padding: 5px;
                        padding-top: 0;
                        padding-bottom: 0;

                        &::before {
                            content: "";
                            height: 30px;
                            width: 2px;
                            font-size: 22px;
                            position: absolute;
                            top: 100%;
                            left: 25%;
                            background: $brand-blue-dark;
                        }
                    }
                }

                .histor_date {
                    background: white;
                    width: auto;
                    height: auto;
                    position: absolute;
                    top: -230%;
                    display: none;
                    left: -20%;
                    padding: 5px;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        .learn_more_history {
            width: 15%;
            margin: 0 auto;
            margin-top: 15px;

            a {
                width: 100%;
            }
        }
    }
    }




@media (min-width:0px) and (max-width:1023px){
    .history_slider {
        padding-top: 50px;
        padding-bottom: 20px;
        background: $grey-background-color;

        .owl-carousel {
            margin: 0 auto;

            .owl-item {
                height: 100%;

                .item {
                    padding: 15px;
                    height: 100%;

                    .slide_content_outer {
                        min-height: 350px;
                        margin: 0 auto;
                        border: 1px solid $brand-blue-dark;
                        background: $white-color;
                        height: 100%;
                        position: relative;

                        .history_slider_img {
                            padding: 15px;

                            img {
                                min-height: 200px;
                            }
                        }

                        .history_slider_content {
                            padding: 15px;
                            margin-bottom: 60px;

                            a {
                                display: inline;
                                width: 50%;
                                margin: 0 auto;
                                padding: 10px;
                                display: none;
                            }

                            h5 {
                                color: $dark-grey-color;
                                font-size: 13px;

                                &:first-child {
                                    padding-top: 0;
                                    display: none;
                                }
                            }

                            p {
                                font-size: 16px;
                                color: $text-blue-color;
                            }
                        }

                        a {
                            position: absolute;
                            bottom: 16px;
                            left: calc(42px + 12%);
                            right: calc(42px + 12%);
                            height: 42px;
                            padding: 10px;
                        }
                    }
                }
            }

            .owl-nav {
                position: static;

                .owl-prev {
                    height: 42px;
                    width: 42px;
                    background-color: $brand-blue-dark;
                    position: absolute;
                    bottom: 27px;
                    left: 12%;
                    transform: translate(0%,0%);
                    color: $white-color;
                    opacity: 1;

                    &:active {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    &:hover {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    span {
                        font-size: 44px;
                        line-height: 0.6;
                    }
                }

                .owl-next {
                    height: 42px;
                    width: 42px;
                    background-color: $brand-blue-dark;
                    position: absolute;
                    bottom: 27px;
                    right: 12%;
                    transform: translate(0%,0%);
                    color: $white-color;
                    opacity: 1;

                    &:active {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    &:hover {
                        background-color: $white-color;
                        color: $brand-blue-dark;
                        border: 1px solid $brand-blue-dark;
                    }

                    span {
                        font-size: 44px;
                        line-height: 0.6;
                    }
                }
            }

            .owl-dots {
                display: none;
                margin-top: 50px;
                width: 40%;
                margin: 0 auto;
                border: 1px solid $brand-blue-dark;

                .active {
                    span {
                        background: $brand-blue-dark;
                    }
                }

                span {
                    border-radius: 0;
                    background: $brand-blue-dark;
                    margin-top: 10px;

                    &:active {
                        background: $brand-blue-dark;
                    }
                }
            }
        }

        .owl-item {
            position: relative;
        }

        .learn_more_history {
            width: 100%;
            margin: 0 auto;

            a {
                width: 100%;
            }
        }
    }
}

.owl-dot{
    position: relative;
}
