.contact-card {

    &__inner {
        &.wrapper {
            background-color: $white-color;
            padding-bottom: 40px;
            max-width: 1200px;
            margin: 0 auto;

            @media (max-width: 767px) {
                padding-bottom: 20px;
                width: 90%;
            }
        }
    }

    &__header {
        color: $white-color;
        font-family: $oswald;
        padding: 20px 15px;
        text-transform: uppercase;
        background-color: $blue;
    }

    &__content {
        margin-top: 40px;

        @media (max-width: 767px) {
            margin-top: 20px;
        }

        @media(min-width: 1024px) {
            padding: 0 20px;
        }
    }

    &__img {
        float: left;

        @media (max-width: 767px) {
            padding: 0;
            margin-bottom: 20px;
        }
    }

    &__info {
        float: left;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 768px) and (orientation: portrait) {
            padding: 0;
        }

        &-section {
            margin-bottom: 20px;
            float: left;

            &:nth-child(2n) {
                @media (max-width: 768px) {
                    padding: 0;
                }
            }

            @media (max-width: 768px) {
                margin-bottom: 10px;
            }

            @media (max-width: 767px) {
                padding: 0;
            }

            p {
                font-family: $oswald;
                margin: 0;
                font-weight: 900;
                margin-bottom: 5px;
            }

            span {
                font-size: .9rem;
                line-height: 1.2rem;
            }
        }
    }

    &__social-link {
        font-size: .9rem;

        @media(min-width: 1024px) {
            padding: 0 15px;
        }
    }
}
