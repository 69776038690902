﻿@import "../base/var";


input, .form-control {
    border-color: #ffffff;
    border-radius: 0;
    background-color: $grey-background-color;
    height: 50px;

    &:focus {
        box-shadow: none;
    }
}

.modal-body {
    .controls {
        .input-medium {
            margin-bottom: 10px;
            @include muli-regular;
        }
    }
}


@media (max-width:767px) {
    .model-login__section {
        .modal {
            padding-right: 0 !important;

            .modal-dialog-centered {
                margin: 0;

                .modal-content {
                    border: none;
                    background-color: transparent;

                    .manage-space {
                        margin-top: 10px;
                        padding: 0 30px;
                        background-color: #f7f7f7;
                    }
                }

                .modal-footer {
                    border-radius: 0;
                }
            }
        }
    }
}

@media(min-width:768px) {
    .manage-space {
        border-right: 1px solid #e6e4e4;
        overflow: hidden;
    }

    .login-wrapper {
        flex-direction: row-reverse;
    }
}
