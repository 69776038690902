@import "../base/var";



@media (min-width:1024px) {
    .column_with_head {
        padding-top: 50px;
        padding-bottom: 15px;
        background-color: $grey-background-color;

        .feature-grid-desktop {
            .container-content {
                padding-left: 15px;

                h3 {
                    padding-left: 16px;
                    font-size: 14px;
                    color: $brand-blue-dark;
                    padding-top: 15px;
                    font-weight: 400;
                }

                .row {


                    .column_header {
                        background: $white-color;
                        border-bottom: 1px solid $grey-background-color;
                        max-width: 24%;
                        flex: 0 0 24%;

                        h1 {
                            font-size: 36px;
                            padding: 25px 30px;
                        }

                        h3 {
                            font-weight: 600;
                            color: $brand-color;
                            padding-left: 0;
                            font-size: 16px;
                        }

                        p, ul, ol {
                            font-size: 10px;
                            color: $text-blue-color;
                        }

                        &.borderless {
                            border-bottom: none;
                        }
                    }

                    .column_info_section {
                        background: $white-color;
                        padding: 15px;
                        padding-top: 25px;
                        border-left: 1px solid $grey-background-color;
                        border-bottom: 1px solid $grey-background-color;
                        position: relative;
                        max-width: 19%;
                        flex: 0 0 19%;

                        a {
                            width: 100%;
                        }

                        i, span {
                            color: $brand-color;
                            left: 50%;
                            top: 50%;
                            position: absolute;
                            transform: translate(-50%,-50%);
                        }

                        h3 {
                            color: $brand-blue-dark;
                        }

                        &.borderless {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .feature-grid-mobile {
        display: none;
    }
}

@media (min-width:0px ) and (max-width:1023px) {
    .feature-grid-desktop {
        display: none;
    }

    .column_with_head {
        .feature-grid-mobile {
            .container {
                .feature-grid-mobile-Collapse {


                    .feature-grid-show-content {
                        margin-bottom: 15px;
                        background: $grey-background-color;

                        a {
                            width: 100%;
                            background: #E6E6E6;
                            font-size: 22px;
                            font-weight: 700;
                            padding: 10px;
                            text-align: left;
                            color: $text-blue-color;

                            i, span {
                                float: right;
                                padding: 10px;
                                font-size: 28px;
                                padding: 5px;
                            }

                            &:active {
                                color: $white-color;
                                background: $brand-color;
                            }

                            &:focus {
                                color: $white-color;
                                background: $brand-color;
                            }
                        }

                        p, ul, ol {
                            font-size: 16px;
                            text-align: left;
                            padding: 15px;
                            padding-top: 0;
                            color: $text-blue-color;
                        }

                        h2 {
                            color: $brand-color;
                            font-size: 16px;
                            padding: 15px;
                            font-weight: 300;

                            i, span {
                                padding-right: 5px;
                            }
                        }
                    }

                    .feature-grid-hide-content {
                        .content-collapse {
                            padding: 15px;
                            border-bottom: 1px solid #E6E6E6;

                            &:last-child {
                                border: none;
                            }

                            p {
                                padding: 0;
                                color: $text-blue-color;
                            }

                            a {
                                color: $white-color;
                                background: $brand-blue-dark;
                                border: 1px solid $white-color;
                                text-align: center;
                                font-weight: 400;
                                font-size: 16px;

                                &:hover {
                                    border: 1px solid $brand-blue-dark;
                                    background: $btn-grey-bg;
                                    color: $brand-blue-dark;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
