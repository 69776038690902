
.find-local-private-banker {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

@media (min-width:1023px) {
    .find-local-private-banker {
        flex-direction: row;
    }
}

.find-local-private-banker .copy {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.find-local-private-banker .expand-collapse {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: right;
    cursor: pointer;
}

.find-local-private-banker .expand {
    display: block;
}

.find-local-private-banker .collapse {
    display: none;
}

.column_advisor section:last-child {
    margin-bottom: 64px;
}