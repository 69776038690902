﻿@import "../base/var";

.search_result {
    background-color: $grey-background-color;
    /*margin-bottom: 20px;*/
    .search-result_outer {
        padding: 40px 0 20px 0;


        .search-result_inner {
            max-width: 1400px;

            .search_bar {
                .search_input {
                    margin: 10px 0;

                    input {
                        width: 50%;
                        padding-left: 15px;
                        background: $white-color;
                        border: none;
                    }
                }

                .search_btn {
                    margin: 25px 0;

                    button {
                        background: $brand-blue-dark;
                        padding: 13px 35px;
                        border-radius: 0;
                        color: $white-color;
                    }
                }
            }

            .search_output {
                margin-left: 0;
                margin-top: 25px;
                padding-top: 18px;
                background: $white-color;

                .search_output_heading {
                    margin-top: 10px;

                    h2 {
                        margin-bottom: 10px;
                        color: $brand-color;
                    }
                }

                .search_output_description {
                    margin-bottom: 10px;
                }
            }

            .pagination-sec {
                .pagination {
                    margin: 20px 0;

                    .page-item {
                        .page-link {
                            background-color: $grey-background-color;
                            color: $text-blue-color;
                            border: none;
                            opacity: 0.5;
                            padding-right: 0;
                        }

                        &.left-angle, &.right-angle {
                            a {
                                background-color: $white-color;
                                border-radius: 0;
                                opacity: 1;
                                padding-right: 12px;

                                i, span {
                                    color: $brand-color;
                                    font-weight: 600;
                                }
                            }

                            &:hover {
                                a {
                                    background-color: $brand-color;

                                    i, span {
                                        color: $white-color;
                                    }
                                }
                            }
                        }
                        /*&:nth-last-child(2), &:nth-child(2) {
                            a {
                                opacity: 1;
                            }
                        }*/
                        &:nth-last-child(2) {
                        }
                    }
                }
            }
        }
    }



    .search-result_outer_result {
        padding: 0px 0 20px 0;


        .search-result_inner {
            max-width: 1400px;

            .search_bar {
                .search_input {
                    margin: 10px 0;

                    input {
                        width: 50%;
                        padding-left: 15px;
                        background: $white-color;
                        border: none;
                    }
                }

                .search_btn {
                    margin: 25px 0;

                    button {
                        background: $brand-blue-dark;
                        padding: 13px 35px;
                        border-radius: 0;
                        color: $white-color;
                    }
                }
            }

            .search_output {
                margin-left: 0;
                /*margin-top: 25px;*/
                padding-top: 18px;
                background: $white-color;

                .search_output_heading {
                    margin-top: 10px;

                    h2 {
                        margin-bottom: 10px;
                        color: $brand-color;
                    }
                }

                .search_output_description {
                    margin-bottom: 10px;
                }
            }

            .pagination-sec {
                .pagination {
                    margin: 20px 0;

                    .page-item {
                        &.active {
                            opacity: 1;
                            a {
                                opacity: 1;
                                background: $gray;
                            }
                        }

                        .page-link {
                            background-color: $grey-background-color;
                            color: $text-blue-color;
                            border: none;
                            opacity: 1;
                            padding-right: 12px;

                            &:hover {
                                opacity: 1;
                                background: $gray;
                                transition: all 0.3s ease-in-out;
                            }
                        }

                        &.left-angle, &.right-angle {
                            a {
                                background-color: $white-color;
                                border-radius: 0;
                                opacity: 1;
                                padding-right: 12px;

                                i, span {
                                    color: $brand-color;
                                    font-weight: 600;
                                }
                            }

                            &:hover {
                                a {
                                    background-color: $brand-color;

                                    i, span {
                                        color: $white-color;
                                    }
                                }
                            }
                        }
                        /*&:nth-last-child(2), &:nth-child(2) {
                            a {
                                opacity: 1;
                            }
                        }*/
                        &:nth-last-child(2) {
                        }

                        &.disabled {
                            &:hover {
                                .page-link {
                                    background-color: $white-color;
                                    cursor: default;
                                }

                                .fa-angle-right, .fa-angle-left {
                                    color: grey;
                                }
                            }

                            .fa-angle-right, .fa-angle-left {
                                color: grey;
                            }
                        }
                    }
                }
            }
        }
    }
}

.no-search-result-msg {

    h1 {
        color: #0073B1;
    }
}
