@import "../base/var";


@media (min-width:1024px){
    .two_column_list{
        background: $white-color;
        padding: 15px;
        padding-top: 40px;
        .two_column_list_outer{
            h2{
                font-size: 30px;
                margin-bottom: 15px;
            }
            .list_content{
                float: left;
                margin-bottom: 20px;

                .text_content{
                    padding: 15px;
                    margin-left: 10%;

                    span{
                        color: $black-color;
                        font-size: 16px;
                    }
                }
                .icon_content{
                    width: 10%;
                    float: left;

                    i, span {
                        font-size: 22px;
                        background: $grey-background-color;
                        height: 55px;
                        width: 55px;
                        padding: 15px;
                        margin-right: 15px;
                        color: $brand-color;
                    }
                }
            }
        }
    }
}


@media (min-width:0px) and (max-width:1023px){
    .two_column_list{
        background: $white-color;
        padding: 15px;
        padding-top: 40px;
        .two_column_list_outer{
            padding: 0;
            h2{
                font-size: 30px;
                margin-bottom: 15px;
            }
            .list_content{
                float: left;
                margin-bottom: 20px;

                .text_content{
                    margin-left: 25%;
                    padding-left: 0px;

                    span{
                        color: $black-color;
                        font-size: 16px;
                    }
                }
                .icon_content{
                    width: 20%;
                    float: left;

                    i, span {
                        font-size: 22px;
                        background: $grey-background-color;
                        height: 55px;
                        width: 55px;
                        padding: 15px;
                        margin-right: 15px;
                        color: $brand-color;
                    }
                }
            }
        }
    }
}
