﻿@import "../base/var";

.stripe {
    &.bg-grey {
        p {
            color: $brand-dark-color;
        }
    }

    &.bg-dark-blue {
        p {
            color: $white-color;
        }
    }

    &.bg-white {
        p {
            color: $brand-dark-color;
        }
    }

    &.bg-light-blue {
        p {
            color: $white-color;
        }
    }

    &.bg-blue {
        p {
            color: $white-color;
        }
    }
}

@media (min-width:1024px) {
    .stripe {
        .stripe-heading {
            padding-top: 15px;
            padding-bottom: 15px;

            * {
                margin: 0;
                font-size: 30px;
            }
        }

        .cta-simple {
            a {
                float: right;
                margin: 0 5px 0 5px;
            }
        }
    }
}

@media (min-width:0px) and (max-width:1023px) {
    .stripe {
        .stripe-heading {
            padding-top: 15px;
            padding-bottom: 15px;

            * {
                font-size: 28px;

                @media (min-width:768px) and (max-width:1023px) {
                    font-size: 30px;
                }

                margin: 0;
            }
        }

        .cta-simple {
            a {
                float: left;
                margin: 0 5px 0 5px;
            }
        }
    }
}
