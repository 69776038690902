﻿.tertiary-nav {
    .vartical_tab_outer {
        .tabpanel_coustom {
            .ter-nav {
                a {
                    position: static;

                    @media(min-width: 767px) {
                        position: relative;
                    }

                    &:before {
                        width: 10px;
                        content: "";
                        height: auto;
                        display: block;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;

                        @media(max-width: 767px) {
                            display: none;
                        }
                    }

                    i {
                        background-color: transparent;
                    }
                }

                a:nth-child(2) {
                    @media(max-width: 767px) {
                        background: $grey-background-color;

                        i {
                            color: $brand-color;
                        }
                    }

                    &:before {
                        background-color: $grey-background-color;
                    }
                }

                a:nth-child(3) {
                    @media(max-width: 767px) {
                        background: $brand-color;

                        i {
                            color: $white-color;
                        }
                    }

                    &:before {
                        background-color: $brand-color;
                    }
                }

                a:nth-child(4) {
                    @media(max-width: 767px) {
                        background: $hard-pink;

                        i {
                            color: $white-color;
                        }
                    }

                    &:before {
                        background-color: $hard-pink;
                    }
                }

                a:nth-child(5) {
                    @media(max-width: 767px) {
                        background: $sky-blue-color;

                        i {
                            color: $brand-dark-color;
                        }
                    }

                    &:before {
                        background-color: $sky-blue-color;
                    }
                }
            }
        }

        .tab-content_coustom {
            .tab-content {
                background-color: $white-color;
            }
        }
    }
}
