.hero-video-banner {
    height: 55vw;
    max-height: 750px;
    overflow: hidden;
    position: relative;

    @media (max-width: 1024px) {
        max-height: 640px;
    }

    @media (max-width: 960px) {
        height: 520px;
    }

    @media(max-width: 520px) {
        max-height: 400px;
        height: 400px;
    }

    &__video-cotent {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;

        @media(max-width: 520px) {
            height: 100%;
        }

        video {
            width: 100%;

            @media(max-width: 960px) {
                width: auto;
                height: 100%;
            }
        }
    }

    &__content {
        width: 460px;
        position: absolute;
        z-index: 2;
        right: 10%;
        color: white;

        @media(max-width: 1024px) {
            width: 420px;
            right: 5%;
            left: auto;
        }

        @media(max-width: 520px) {
            width: 84%;
            left: 8%;
        }

        h1 {
            color: white;
            font-size: 38px;
            margin-bottom: 20px;
            line-height: 1.2em;
            font-family: "Lato",sans-serif;

            @media (max-width: 1024px) {
                font-size: 2rem;
            }

            @media(max-width: 520px) {
                font-size: 1.5rem;
            }
        }

        p {
            font-size: 1.2rem;
            color: white;
            font-weight: 500;
            line-height: 1.5em;
            font-family: "Lato",sans-serif;
            margin-bottom: 0;

            @media (max-width: 1024px) {
                font-size: 1rem;
            }

            @media(max-width: 520px) {
                font-size: 0.9rem;
            }
        }

        .btn {
            margin-top: 25px;
        }
    }
}
