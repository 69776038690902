@import "../base/var";



.two_column_section {
    overflow: hidden;
    margin: 70px 0;

    h2 {
        color: $text-blue-color;
        @include archivo-bold(30px);
        text-align: left;
        margin-bottom: 20px;

        @media(min-width:0px) and (max-width:767px) {
            display: none;
        }
    }

    .container {
        .row {
            position: relative;
            /*height: 580px;*/
            .two_column_section_left {
                background-color: white;
                min-height: 500px;
                height: 100%;
            }

            .two_column_section_right {
                background-color: $white-color;
                min-height: 500px;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                opacity: 0.1;
            }

            .container-content {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 45px;

                @include ie-only {
                    left: 82px;
                }

                .row {
                    height: 100%;

                    .left {
                        padding-right: 0;
                        height: 100%;
                        display: table;

                        .two_column_section_left_outer {
                            display: table-cell;
                            vertical-align: middle;
                            padding-left: 50px;
                            padding-right: 50px;

                            @media (min-width: 992px) and (max-width: 1386px) {
                                padding-left: 0;
                                padding-right: 0;
                            }

                            .store-img {
                                img {
                                    margin: 10px;
                                    margin-left: 0;
                                }
                            }

                            h5 {
                                font-size: 14px;
                                color: #808080;
                                padding-top: 15px;
                            }
                        }
                    }

                    .middle {
                        height: 100%;
                        position: relative;

                        .two_column_section_center_outer {
                            height: 100%;
                            background-repeat: no-repeat;
                            background-position: top;
                            margin: 0 auto;
                            background-size: 100%;
                            bottom: 0;
                            position: absolute;
                            min-height: 375px;
                            width: 100%;
                            /*img {
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                bottom: 0;
                                min-height: 500px;
                            }*/
                        }
                    }

                    .right {
                        height: 100%;
                        display: table;

                        .two_column_section_right_outer {
                            display: table-cell;
                            vertical-align: middle;
                            padding-left: 50px;
                            padding-right: 50px;

                            @media (min-width: 992px) and (max-width: 1386px) {
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }

                    .two_column_section_left_outer {
                        h2 {
                            color: $text-blue-color;
                            font-size: 24px;
                        }

                        h5 {
                            color: #808080;
                            padding-top: 15px;
                        }

                        p {
                            color: $text-blue-color;
                            font-size: 14px;
                        }

                        a {
                            color: $brand-light-color;
                            font-size: 16px;
                        }
                    }

                    .two_column_section_center_outer {
                        img {
                            margin: 0 auto;
                            align-self: center;
                            align-items: center;
                            text-align: center;
                            display: block;
                        }
                    }

                    .two_column_section_right_outer {
                        h2 {
                            color: $text-blue-color;
                            font-size: 24px;
                        }

                        h5 {
                            color: #808080;
                            padding-top: 15px;
                            font-size: 14px;
                        }

                        p {
                            color: $text-blue-color;
                            font-size: 14px;
                        }

                        a {
                        }
                    }
                }
            }
        }
    }
}


@media(min-width:0px) and (max-width:767px) {
    .two_column_section {


        .two_column_section_mobile {
            .two_column_section_mobile_img {
                min-width: 200px;
                background-color: $white-color;
                background-position: top;
                background-size: cover;
                margin: 0 auto;
                min-height: 300px;
                max-height: 320px;
                overflow: hidden;

                img {
                    margin: 0 auto;
                    display: block;
                    align-self: center;
                    text-align: center;
                }

                .img_section {
                    width: 80%;
                    margin: 0 auto;

                    img {
                        padding: 30px 30px 0px 30px;
                    }
                }
            }

            .two_column_section_left_outer_mobile {
                .store-img {
                    width: 80%;
                    margin: 0 auto;
                    margin: 0 auto;
                    width: fit-content;

                    img {
                        margin: 10px;
                    }
                }

                h2 {
                    padding: 20px 15px;
                    color: $text-blue-color;
                    display: block;
                    font-size: 26px;
                }

                .btn {
                    background-color: #f2f2f2;
                    width: auto;
                    margin: 0 auto;
                    display: block;
                    height: auto;
                    text-align: center;
                    padding-top: 13px;
                    vertical-align: middle;
                    color: $brand-dark-color;
                    @include muli-bold(17px);
                    margin-bottom: 5px;
                }

                h5 {
                    color: #808080;
                    padding-top: 5px;
                    @include muli-bold(10px);
                    text-align: center;
                }

                p {
                    @include muli-semibold(12px);
                    padding: 10%;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    color: $text-blue-color;
                }
            }
        }
    }
}

@media(min-width:768px) and (max-width:992px) {
    .two_column_section {
        .two_column_section_mobile {
            .two_column_section_mobile_img {
                min-width: 200px;
                background-color: $white-color;

                .img_section {
                    width: 50%;
                    margin: 0 auto;

                    img {
                        padding: 30px 30px 0px 30px;
                    }
                }
            }

            .two_column_section_left_outer_mobile {
                padding-top: 30px;
                h2 {
                    color: $text-blue-color;
                    font-size: 26px;
                }

                .store-img {
                    margin: 0 auto;
                    margin: 0 auto;
                    width: fit-content;

                    img {
                        margin: 10px;
                    }
                }

                .btn {
                    background-color: #f2f2f2;
                    margin: 0 auto;
                    display: block;
                    height: 50px;
                    text-align: center;
                    padding-top: 13px;
                    vertical-align: middle;
                    color: $brand-dark-color;
                    @include muli-bold(17px);
                }

                h5 {
                    color: #808080;
                    padding-top: 5px;
                    font-size: 10px;
                    text-align: center;
                    font-size: 12px;
                }

                p {
                    @include muli-semibold(14px);
                    color: $text-blue-color;
                    width: 70%;
                    margin: 0 auto;
                    padding: 10px;
                    line-height: 1.2;
                    padding-right: 0;
                    letter-spacing: 0;
                }
            }
        }
    }
}


.two_column_overlay_right {
    position: absolute;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 0.9;
    top: 0;
    right: -1px;
    bottom: 0;
    min-width: 100%;
}