@import "../../../../Project/Website/code/Styles/commonstyle";

.location-section {
    .sec-col1 {
        background-position: center;
        background-size: cover;
        padding: 0;
    }

    .banner-layer {
        margin: 0;
        width: 100%;
        @include background-layer;

        @media(max-width: 767px) {
            margin: 0;
        }

        span {
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;

            @media(max-width:800px) {
                margin-bottom: 18px;
                line-height: 1.5;
            }
        }

        .button-outline {
            background: 0 0;
            border: solid 2px #fff;
            color: $white-color;
            padding: 12px 16px;
            border-radius: 4px;

            &:hover {
                background: #fff;
                color: #28aae1;
                text-decoration: none;
            }

            i {
                margin-right: 2px;
            }
        }
    }

    .sec-con1 {
        @media(max-width: 767px) {
            margin: 0 auto;
            padding: 0;
        }

        .sec-row1 {
            @media(max-width: 767px) {
                margin: 0 auto;
            }
        }

        .location-banner {
            @media(max-width: 767px) {
                margin: 0;
            }
        }

        .location-data {
            @media(max-width: 767px) {
                margin: 0;
            }

            &.hidden {
                height: 0;
                visibility: hidden;
            }

            &.show {
                height: auto;
                visibility: visible;
            }
        }

        .landing-hero-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0.85;
            z-index: 0;
        }

        .home-searchbar {
            height: 550px;
            display: flex;
            justify-content: center;
            position: relative;
            align-items: center;

            .typehead {
                text-align: center;
                width: $width;

                @media(max-width: 767px) {
                    padding: 0 15px;
                }

                @media (min-width: 768px) and (max-width: 1024px) {
                    width: 75%;
                }

                .search-results {
                    position: relative;

                    input {
                        width: 100%;
                        border-radius: 3px;
                        border: none;
                        padding: 14px 12px 14px;
                        box-sizing: border-box;
                        font-size: 1.1rem;
                        letter-spacing: .2px;
                        text-overflow: ellipsis;
                        color: black !important;

                        &:focus {
                            outline: none;
                        }
                    }

                    .autocomplete-items {
                        width: 100%;
                        text-align: left;
                        background-color: white;
                        min-height: 164px;
                        position: absolute;
                        width: 100%;

                        div {
                            padding: 4px 12px;
                            border-bottom: 1px solid gray;
                            color: #666 !important;

                            &.autocomplete-active {
                                background-color: #ddd;
                            }

                            strong {
                                color: #666 !important;
                            }

                            &:hover {
                                background-color: #F2F2F2;
                                cursor: pointer;
                            }

                            &:last-child {
                                border: none;
                            }
                        }
                    }
                }

                .map-head {
                    margin: 0 auto;
                    max-width: 767px;

                    label {
                        color: white;
                        display: none;
                        margin-top: 15px;
                        color: red;
                    }

                    h1 {
                        margin-bottom: 15px;
                        font-weight: 800;

                        @media(max-width:1024px) {
                            font-size: 22px;
                        }
                    }

                    p {
                        margin-bottom: 20px;
                    }

                    #pac-input {
                        width: $width;
                        border-radius: 3px;
                        border: none;
                        padding: 14px 12px 14px;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        font-size: 1.1rem;
                        letter-spacing: .2px;
                        text-overflow: ellipsis;
                        color: black;

                        &:focus {
                            border: none;
                            outline: none;
                        }
                    }

                    .controls {
                        margin-top: 10px;
                        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
                    }
                }
            }
        }

        .location-map {
            display: none;
        }

        .location-list {
            background: #f7f7f7;
            height: 700px;
            overflow: hidden;
            overflow-y: scroll;

            @media(max-width: 767px) {
                height: auto;
            }

            .choose-location {
                padding: 12px 18px;
                background: #f0f0f0;
                text-align: right;

                span {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: .75rem;
                    margin-right: 5px;

                    i {
                        margin-right: 3px;
                    }
                }

                select {
                    border-radius: 3px;
                    padding: 5px 8px;
                    font-size: .75rem;
                }
            }

            .location-list-display {
                .info-block {
                    @include block;
                    background: #f7f7f7;
                    cursor: pointer;

                    &.active {
                        background-color: #fdfdfd;
                    }

                    &:hover {
                        @extend .active;
                    }

                    .info-icon {
                        @include icon;

                        @media(max-width:768px) {
                            width: 30px;
                            font-size: 1.4rem;
                        }
                    }

                    .info-info {
                        @include info;

                        @media(max-width:768px) {
                            width: calc(100% -130px);
                        }
                    }

                    .info-more {
                        @include more;
                    }

                    .clear {
                        clear: both;
                    }

                    .mobile-link {
                        @include mobile-link;
                    }
                }

                .active {
                    background-color: #fdfdfd;
                    border-top: none;
                }
            }
        }

        .city-map {
            @include map($min-height);
        }
    }

    .edge {
        .banner-layer {
            margin: 0;
        }
    }
}




.pac-logo {
    &:after {
        display: none;
    }
}

.gm-style {
    .gm-style-iw-t {
        @extend .pac-logo;
    }
}

.pac-container {
    display: none;
}
