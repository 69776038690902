@import "../base/var";

.alternative_callout {
    &.no-margin {
        margin: 0;
    }
}

@media (min-width: 1024px) {
    .mobile-alternativeCallout {
        display: none;
    }

    .alternative_callout {
        position: relative;
        margin: 50px 0;

        .container {
            padding: 0;

            .alternative_callout_outer {
                position: relative;
                min-height: 250px;

                .alternative_callout_outer_left {
                    min-height: 250px;
                    float: left;
                    background-repeat: no-repeat;
                    background-size: cover;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    background-size: cover;
                    background-position: center center;
                    position: absolute;


                    svg {
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 1;

                        rect {
                            height: 100%;
                        }
                    }

                    iframe {
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }

                .alternative_callout_outer_right {
                    min-height: 250px;
                    top: 0;
                    bottom: 0;
                    float: left;
                    position: absolute;
                    right: 0;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;

                    a {
                        float: right;
                        color: $white-color;
                        padding: 15px;

                        i {
                        }
                    }

                    svg {
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;

                        rect {
                            height: 100%;
                        }
                    }

                    iframe {
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }

                .alternative_callout_inner {
                    position: relative;
                    top: 10%;
                    bottom: 10%;
                    min-height: 200px;

                    .alternative_callout_inner_left {
                        float: left;
                        padding: 25px;
                        padding-right: 50px;
                        position: static;
                        min-height: 200px;

                        h1 {
                            font-size: 30px;
                        }

                        > div {
                            ul {
                                list-style-type: disc;
                                li{
                                    a{
                                        display:contents;
                                    }
                                }
                            }
                        }

                        a {
                            margin-top: 20px;
                            width: auto;
                            display: table;
                        }

                        iframe {
                            width: 100%;
                            height: 100%;
                        }

                        svg {
                            position: absolute;
                            right: -40px;
                            top: 0;
                        }

                        .img-overlay-content {
                            display: flex;
                            -ms-flex-align: center;
                            align-items: center;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translate(0%, -50%);

                            img {
                                padding: 0 30px;
                            }
                        }
                    }

                    .alternative_callout_inner_right {
                        padding: 25px;
                        padding-left: 60px;
                        padding-right: 0px;
                        position: static;
                        width: 50%;
                        float: right;
                        min-height: 200px;

                        p {
                            width: 80%;
                            overflow-wrap: break-word;
                        }

                        img {
                            margin: 0 auto;
                            display: block;
                        }

                        a {
                            margin-top: 20px;
                            width: auto;
                            display: table;
                        }

                        iframe {
                            width: 100%;
                            height: 100%;
                            float: right;
                        }

                        svg {
                            position: absolute;
                            left: -30px;
                            top: 0;
                        }

                        .img-overlay-content {
                            display: flex;
                            -ms-flex-align: center;
                            align-items: center;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(0%, -50%);

                            img {
                                padding: 0 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width :0px) and (max-width:1023px) {
    .alternative_callout {
        .desktop-view {
            display: none;
        }

        .mobile-alternativeCallout {
            [class*= "mobile-alternativeCallout-"] {
                min-height: 248.17px;
                width: 100%;
                background-position: center;
                padding: 0;
                background-size: cover;
                display: flex;

                iframe {
                    width: 100%;
                    height: 250px;
                }

                .content-block {
                    padding: 20px 15px;

                    h2 {
                        font-size: 26px;
                        color: $text-blue-color;
                    }

                    p {
                        font-family: $muliFont;
                        color: $brand-blue-dark;
                        font-size: 16px;
                    }

                    a {
                        padding: 12px 20px;
                        width: auto;
                        display: block;
                    }

                    iframe {
                        width: 100%;
                        height: 250px;
                    }

                    svg {
                        display: none;
                    }
                }

                .img-overlay-content {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;

                    > div {
                        margin: 0 auto;

                        img {
                            padding: 0 30px;
                        }
                    }
                }
            }

            .mobile-alternativeCallout-right {
                &.has-image {
                    padding-top: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }

            .mobile-alternativeCallout-left {
                &.has-image {
                    padding-top: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    order: 2;
                }

                .content-block {
                    > div {
                        ul {
                            list-style-type: disc;
                        }
                    }
                }
            }
        }
    }
}
